import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useStatisticsAnnualWaterConsumption(
  year,
  params,
  options,
  key = "getAnnualWaterConsumption",
) {
  const axiosPrivate = useAxiosPrivate();
  const getAnnualWaterConsumption = async () => {
    const { data } = await axiosPrivate({
      method: "GET",
      url: `Statistics/WaterConsumption/Annual/${year}`,
      params,
    });

    const mappedData = {
      Year: data.Year,
      TotalWaterConsumption: data.TotalWaterConsumption / 1000,
      StatisticsData: data.StatisticsData.map((s) => ({
        Month: s.Month,
        MonthAsInt: s.MonthAsInt,
        WaterConsumption: s.WaterConsumption / 1000,
        Offline: s.Offline,
        Check: s.Check,
        Leak: s.Leak,
        HasOffline: s.HasOffline,
        HasCheck: s.HasCheck,
        HasLeak: s.HasLeak,
      })),
    };

    return mappedData;
  };

  return useQuery([key], () => getAnnualWaterConsumption(year, params), {
    ...options,
  });
}

export default useStatisticsAnnualWaterConsumption;
