import { useState, useEffect } from "react";

import {
  CloseOutlined,
  ArrowLeftOutlined,
  FrownOutlined,
  SignalFilled,
  ThunderboltFilled,
} from "@ant-design/icons";
import {
  Drawer,
  Descriptions,
  Divider,
  Skeleton,
  Alert,
  Select,
  Button,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";

import useDetailsByDeviceId from "../../../api/hooks/useDetailsByDeviceId";
import useDeviceCommandSubmit from "../../../api/hooks/useDeviceCommandSubmit";
import DeviceType from "../../../constants/DeviceType";
import convertToF from "../../../helpers/convertToF";
import removeCommandFromStorage from "../../../helpers/removeCommandFromStorage";
import useWindowSize from "../../../hooks/useWindowSize";

function FloorSystemDeviceDetails() {
  const navigate = useNavigate();
  const { deviceId } = useParams();
  const window = useWindowSize();

  const activeCommandsOnMount =
    JSON.parse(localStorage.getItem("commands")) ?? [];
  const [currentCommand] = activeCommandsOnMount.filter(
    (command) => command.deviceId === deviceId,
  );

  const [disableCommands, setDisableCommands] = useState(
    !!currentCommand || false,
  );
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [deviceCommandError, setDeviceCommandError] = useState("");

  const {
    data: deviceDetails,
    status,
    refetch: getDeviceDetails,
  } = useDetailsByDeviceId(deviceId, {
    cacheTime: 0,
  });

  const { Device: deviceObject, DeviceCommands } = deviceDetails ?? {};
  const { Device, DeviceStatus, DeviceStatusInfo } = deviceObject ?? {};

  const { isLoading: commandIsSending, mutate: postDeviceCommand } =
    useDeviceCommandSubmit();

  const handleDeviceCommandSubmit = () => {
    const { DisplayName, ...dataToSend } = DeviceCommands[selectedOption] ?? {};

    if (dataToSend) {
      postDeviceCommand(dataToSend, {
        onSuccess: () => {
          localStorage.setItem(
            "commands",
            JSON.stringify([
              ...activeCommandsOnMount,
              { deviceId, statusBeforeCommand: dataToSend.Status },
            ]),
          );

          setDisableCommands(true);
        },
        onError: () => {
          setDeviceCommandError("Couldn't send command");
        },
      });
    }
  };

  let commandInterval = null;
  let commandTimeout = null;

  const triggerDeviceCommandInterval = () => {
    const terminateCommandInterval = () => {
      clearInterval(commandInterval);
      commandInterval = null;
      setSelectedOption(null);
      setDisableCommands(false);
      removeCommandFromStorage(deviceId);
    };

    return new Promise((resolve, reject) => {
      // terminate interval after 90sec
      commandTimeout = setTimeout(() => {
        terminateCommandInterval(commandInterval);
        reject(console.log("Device command timed out"));
      }, 90000); // 90sec

      if (disableCommands && !commandInterval) {
        commandInterval = setInterval(() => {
          getDeviceDetails()
            .then((res) => {
              if (
                currentCommand?.statusBeforeCommand !==
                res?.data?.Device?.DeviceStatus?.Status
              ) {
                terminateCommandInterval(commandInterval);
                clearTimeout(commandTimeout);
              }
            })
            .catch(() => setDeviceCommandError("Can't read previous status"));
        }, 5000);
      }
    });
  };

  useEffect(() => {
    if (disableCommands) {
      triggerDeviceCommandInterval();
    }
  }, [disableCommands]);

  return (
    <Drawer
      key="floorSystemDeviceDetailsDrawer"
      placement="right"
      open={deviceId}
      width={window?.width > 992 ? "420px" : "100%"}
      headerStyle={{ borderBottom: "none" }}
      destroyOnClose
      onClose={() => navigate(-1)}
      closeIcon={
        window?.width <= 992 ? (
          <ArrowLeftOutlined className="drawer-details-close-button" />
        ) : (
          <CloseOutlined className="drawer-details-close-button" />
        )
      }
    >
      <div className="drawer-details">
        {status === "loading" && (
          <Skeleton
            active
            paragraph={{
              rows: 10,
            }}
          />
        )}

        {!deviceDetails && status !== "loading" && (
          <Alert
            message="We have bad news"
            description="Еither we broke something or the device you are looking for does not exist."
            type="error"
            showIcon
            icon={<FrownOutlined />}
          />
        )}

        {status === "success" && deviceDetails && (
          <>
            <h1 className="drawer-details-title">
              {Device?.DeviceTypePublicName || Device?.DeviceType}
            </h1>
            <div className="drawer-details-subtitle">
              <div className="break-all sm:w-1/2 truncate">
                System: {deviceDetails?.SystemName || "Unnamed system"}
              </div>
              <Divider type="vertical" className="hidden sm:inline-block" />
              <div className="break-all sm:w-1/2 truncate">
                Floor: {deviceDetails?.FloorName || "Unnamed floor"}
              </div>
            </div>
            <Descriptions
              bordered={false}
              className="text-16"
              colon={false}
              column={1}
              labelStyle={{
                background: "#2F3342",
                width: "50%",
              }}
            >
              <Descriptions.Item
                label="Location"
                className="drawer-details-description-row"
              >
                <span className="drawer-details-description-item">
                  {Device?.Location || "-"}
                </span>
              </Descriptions.Item>
              <Descriptions.Item
                label="Status"
                className="drawer-details-description-row"
              >
                <span className="drawer-details-description-item">
                  {DeviceStatus?.Status || "-"}
                </span>
              </Descriptions.Item>
              {Device.DeviceTypePublicName === DeviceType.IrrigationStation ||
              (Device.DeviceTypePublicName ===
                DeviceType.IrrigationController &&
                DeviceStatusInfo?.BL === null) ||
              DeviceStatusInfo?.BL === 0 ? null : (
                <Descriptions.Item
                  label="Battery"
                  className="drawer-details-description-row"
                >
                  <span className="drawer-details-description-item">
                    <ThunderboltFilled className="mr-1" />
                    {DeviceStatusInfo?.BL || "0"}%
                  </span>
                </Descriptions.Item>
              )}
              {Device.DeviceTypePublicName === DeviceType.IrrigationStation &&
              DeviceStatusInfo?.Temp === null ? null : (
                <Descriptions.Item
                  label="Temperature"
                  className="drawer-details-description-row"
                >
                  <span className="drawer-details-description-item">
                    {`${DeviceStatusInfo?.Temp} °C / ${
                      DeviceStatusInfo?.Temp &&
                      convertToF(DeviceStatusInfo.Temp)
                    } °F` || "-"}
                  </span>
                </Descriptions.Item>
              )}
              {Device.DeviceTypePublicName === DeviceType.Hub ||
              (Device.DeviceTypePublicName === DeviceType.IrrigationStation &&
                DeviceStatusInfo?.RSSI === null) ||
              DeviceStatusInfo?.RSSI === 0 ? null : (
                <Descriptions.Item
                  label="Signal"
                  className="drawer-details-description-row"
                >
                  <span className="drawer-details-description-item">
                    <div className="w-fit relative">
                      <SignalFilled className="mr-1" />
                      {DeviceStatusInfo?.RSSI ? (
                        `${DeviceStatusInfo?.RSSI} dBm`
                      ) : (
                        <span className="bg-triple-red rounded-full absolute bottom-0 -right-1 w-3 h-3 flex items-center justify-center">
                          <span className="text-10">x</span>
                        </span>
                      )}
                    </div>
                  </span>
                </Descriptions.Item>
              )}

              <Descriptions.Item
                label="Last Modified"
                className="drawer-details-description-row"
              >
                <span className="drawer-details-description-item">
                  {new Date(DeviceStatusInfo?.LM).toLocaleString() || "-"}
                </span>
              </Descriptions.Item>
              <Descriptions.Item
                label="Serial Number"
                className="drawer-details-description-row"
              >
                <span className="drawer-details-description-item">
                  {Device?.SerialNumber || "-"}
                </span>
              </Descriptions.Item>
              {DeviceCommands?.length > 0 &&
                DeviceStatus?.Status !== "Offline" && (
                  <Descriptions.Item
                    label="Command"
                    className="drawer-details-description-row"
                  >
                    <Select
                      className="ml-auto w-full sm:w-3/5 md:w-1/2 lg:w-4/5"
                      allowClear
                      placeholder="Choose a command"
                      disabled={disableCommands || commandIsSending}
                      value={selectedOption}
                      options={DeviceCommands?.map((command, commandIndex) => {
                        return {
                          value: commandIndex,
                          label: command.DisplayName,
                        };
                      })}
                      onChange={(value) => {
                        setSelectedOption(value);

                        if (deviceCommandError) {
                          setDeviceCommandError("");
                        }
                      }}
                    />
                  </Descriptions.Item>
                )}
            </Descriptions>
          </>
        )}
        {deviceCommandError && (
          <span className="flex ml-auto text-triple-red mt-2 font-light">
            <FrownOutlined className="mr-1" /> {deviceCommandError}
          </span>
        )}
        <Button
          type="primary"
          size="large"
          className="flex ml-auto mt-auto"
          disabled={selectedOption === undefined}
          loading={disableCommands || commandIsSending}
          onClick={handleDeviceCommandSubmit}
        >
          {!disableCommands ? "Submit Command" : "Sending Command"}
        </Button>
      </div>
    </Drawer>
  );
}

export default FloorSystemDeviceDetails;
