import PropTypes from "prop-types";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import StatisticsTooltip from "./StatisticsTooltip";
import YAxisLabel from "./StatisticsYAxisLabel";

function StatisticsAnnualChart({ waterConsumptionData, setSelectedMonth }) {
  const handleBarClick = (month) => {
    setSelectedMonth(month);
  };

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      className="bg-triple-white rounded-b-xl pl-1"
    >
      <BarChart data={waterConsumptionData?.StatisticsData}>
        <CartesianGrid strokeDasharray="1 0" vertical={false} />
        <XAxis dataKey="Month" />
        <YAxis dataKey="WaterConsumption" name="Consumption">
          <Label content={YAxisLabel("m3")} />
        </YAxis>
        <Tooltip
          labelStyle={{ color: "#808080" }}
          content={
            <StatisticsTooltip
              year={waterConsumptionData?.Year}
              payload={waterConsumptionData?.StatisticsData}
              active
              label=""
              chartType="Annual"
            />
          }
        />
        <Legend verticalAlign="top" align="right" height={40} />
        <Bar
          dataKey="WaterConsumption"
          name="Consumption"
          fill="#03a5d2"
          barSize={20}
          minPointSize={5}
          onClick={handleBarClick}
          style={{ cursor: "pointer" }}
        >
          {waterConsumptionData?.StatisticsData.map((entry) => (
            <Cell
              fill={entry.WaterConsumption === 0 ? "none" : "#03a5d2"}
              key={entry.MonthAsInt}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

StatisticsAnnualChart.defaultProps = {
  waterConsumptionData: {},
  setSelectedMonth: () => {},
};

StatisticsAnnualChart.propTypes = {
  waterConsumptionData: PropTypes.object,
  setSelectedMonth: PropTypes.func,
};
export default StatisticsAnnualChart;
