import { Menu } from "antd";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import AnalyticsIcon from "../../assets/icons/sidebar/AnalyticsIcon";
import DashboardIcon from "../../assets/icons/sidebar/DashboardIcon";
import EventsIcon from "../../assets/icons/sidebar/EventsIcon";
import FlowConfigurationIcon from "../../assets/icons/sidebar/FlowConfigurationIcon";
import LogoutIcon from "../../assets/icons/sidebar/LogoutIcon";
import ProfileIcon from "../../assets/icons/sidebar/ProfileIcon";
import PropertyOverviewIcon from "../../assets/icons/sidebar/PropertyOverviewIcon";
import ScheduleIcon from "../../assets/icons/sidebar/ScheduleIcon";
import SystemPreferencesIcon from "../../assets/icons/sidebar/SystemPreferencesIcon";
import UsersIcon from "../../assets/icons/sidebar/UsersIcon";
import useAuth from "../../hooks/useAuth";
import useLogout from "../../hooks/useLogout";
import useWindowSize from "../../hooks/useWindowSize";

import "./Sidebar.scss";

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = { className: "" };

// used to generate the items for the sidebar [ant design specific]
function getItem(label, key, icon, children, type, user) {
  if (key === "preferences") {
    if (user?.Roles?.includes("MonitorStationAdmin")) {
      return {
        key,
        icon,
        children,
        label,
        type,
      };
    }

    return {
      key: "forbidden",
      disabled: true,
    };
  }

  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

function Sidebar({ className }) {
  const { auth } = useAuth();
  const window = useWindowSize();
  const logout = useLogout();

  const items = [
    getItem(
      <NavLink to="/" className="nav-link" end>
        <DashboardIcon />
        Dashboard
      </NavLink>,
      "dashboard",
    ),
    getItem(
      <NavLink to="/property-overview" className="nav-link">
        <PropertyOverviewIcon />
        Property Overview
      </NavLink>,
      "property-overview",
    ),
    getItem(
      <NavLink to="/statistics" className="nav-link">
        <AnalyticsIcon />
        Statistics
      </NavLink>,
      "statistics",
    ),
    getItem(
      <NavLink to="/events" className="nav-link">
        <EventsIcon />
        Events
      </NavLink>,
      "events",
    ),
    getItem("Settings", "settings", null, [
      getItem(
        <NavLink to="/flow-configuration" className="nav-link">
          <FlowConfigurationIcon />
          Flow Configuration
        </NavLink>,
        "flow-configuration",
      ),
      getItem(
        <NavLink to="/schedule" className="nav-link">
          <ScheduleIcon />
          Schedule
        </NavLink>,
        "schedule",
      ),
    ]),
    getItem(
      "Preferences",
      "preferences",
      <span className="">
        <SystemPreferencesIcon />
      </span>,
      [
        getItem(
          <NavLink to="/users" className="nav-link hidden">
            <UsersIcon />
            Users
          </NavLink>,
          "users",
        ),
        getItem(
          <NavLink to="/property-management" className="nav-link">
            <PropertyOverviewIcon />
            Property Management
          </NavLink>,
          "property-management",
        ),
      ],
      null,
      auth.user,
    ),
    getItem(
      <NavLink to="/profile" className="nav-link">
        <ProfileIcon />
        Profile
      </NavLink>,
      "profile",
    ),
    getItem(
      <NavLink to="/login" className="nav-link" onClick={logout}>
        <LogoutIcon />
        Logout
      </NavLink>,
      "logout",
    ),
  ];

  return (
    <Menu
      className={`sidebar bg-triple-sidebar text-triple-white ${className}`}
      items={window?.width <= 992 ? items : items.slice(0, -2)}
      mode="inline"
    />
  );
}

Sidebar.propTypes = propTypes;
Sidebar.defaultProps = defaultProps;

export default Sidebar;
