/* eslint-disable no-debugger */
import PropTypes from "prop-types";

function ChartTitle({ waterConsumptionData, selectedMonth }) {
  return (
    <div className="bar-chart-title bg-triple-white rounded-t-xl flex p-1 mt-1">
      <span className="text-triple-header self-end w-1/2">Consumption</span>
      <span className="rounded-md px-4 py-1 bg-triple-blue">
        {waterConsumptionData?.Year}{" "}
        {selectedMonth ? selectedMonth.Month : null}
      </span>
    </div>
  );
}
ChartTitle.defaultProps = {
  waterConsumptionData: {},
  selectedMonth: {},
};

ChartTitle.propTypes = {
  waterConsumptionData: PropTypes.object,
  selectedMonth: PropTypes.object,
};
export default ChartTitle;
