import { Avatar, Dropdown } from "antd";
import { NavLink } from "react-router-dom";

import LogoutIcon from "../../../assets/icons/sidebar/LogoutIcon";
import ProfileIcon from "../../../assets/icons/sidebar/ProfileIcon";
import useAuth from "../../../hooks/useAuth";
import useLogout from "../../../hooks/useLogout";

const propTypes = {};

const defaultProps = {};

function UserDetails() {
  const logout = useLogout();
  const { auth } = useAuth();

  // UserDetails dropdown items
  const items = [
    {
      key: "go-to-profile-button",
      label: (
        <NavLink to="/profile" className="flex items-center">
          <ProfileIcon />
          <span className="font-light ml-2">Profile</span>
        </NavLink>
      ),
    },
    {
      key: "logout-button",
      label: (
        <NavLink to="/login" className="flex items-center" onClick={logout}>
          <LogoutIcon />
          <span className="font-light ml-2">Logout</span>
        </NavLink>
      ),
    },
  ];

  const userDropdownMenu = (menu) => (
    <div className="user-details-dropdown-content">{menu}</div>
  );

  return (
    <Dropdown
      className="user-details"
      dropdownRender={userDropdownMenu}
      menu={{ items }}
      placement="bottom"
      trigger="click"
      overlayStyle={{ width: "150px" }}
    >
      {/* TODO: user img OR default img */}
      <Avatar
        icon={!auth?.user?.ImageUrl ? <ProfileIcon /> : null}
        src={auth?.user?.ImageUrl ? auth?.user?.ImageUrl : null}
        className="flex items-center justify-center cursor-pointer bg-[#51566e] text-triple-background mx-2"
      />
    </Dropdown>
  );
}

UserDetails.propTypes = propTypes;
UserDetails.defaultProps = defaultProps;

export default UserDetails;
