import { useEffect, useRef, useState } from "react";

import { FrownOutlined } from "@ant-design/icons";
import { Form, Input, notification, Select } from "antd";

import useUpdateUserBasicsV2 from "../../api/hooks/useUpdateUserBasicsV2";
import useUserBasicsV2 from "../../api/hooks/useUserBasicsV2";
import CountryFlags from "../../assets/icons/country-flags/CountryFlags";
import RegularExpressions from "../../constants/RegExpressions";
import useAuth from "../../hooks/useAuth";
import SaveChangesButton from "../Shared/SaveChangesButton";
// import Toggle from "../Shared/Toggle";
import "./Profile.scss";

function Profile() {
  const { auth } = useAuth();
  const { refetch: getUserBasics } = useUserBasicsV2();
  const [isLoading, setIsLoading] = useState(false);
  const [userBasicsForm] = Form.useForm();
  const [disableSaveChanges, setDisableSaveChanges] = useState(true);
  const { mutate: updateUserBasicsV2 } = useUpdateUserBasicsV2();
  const initialFormValues = useRef({});
  useEffect(() => {
    if (auth.isAuthenticated) {
      getUserBasics().then((res) => {
        userBasicsForm.setFieldsValue(res.data);
        initialFormValues.current = res.data;
        initialFormValues.current.WaterUnit =
          res.data?.WaterUnit?.toLowerCase();
        initialFormValues.current.TemperatureUnit =
          res.data?.TemperatureUnit?.toLowerCase();
      });
    }
  }, [auth.isAuthenticated, getUserBasics, userBasicsForm]);

  const handleFieldsChanges = (changedFields) => {
    const fieldName = changedFields[0]?.name[0];
    const fieldValue = changedFields[0]?.value;
    const userProfileValues = userBasicsForm.getFieldsValue();
    if (fieldName === "PhoneNumber") {
      const isValidInput = RegularExpressions.NumbersOnly.test(fieldValue);
      if (isValidInput) {
        userBasicsForm.setFields([{ name: "PhoneNumber", errors: [] }]);
      } else {
        setDisableSaveChanges(true);
        userBasicsForm.setFields([
          {
            name: "PhoneNumber",
            errors: ["Phone Number should contain only digits."],
          },
        ]);
      }
    }

    if (
      userProfileValues?.FirstName !== initialFormValues.current.FirstName ||
      userProfileValues?.LastName !== initialFormValues.current.LastName ||
      userProfileValues?.PhoneNumber !== initialFormValues.current.PhoneNumber
      // userProfileValues.Language !== initialFormValues.current.Language ||
      // userProfileValues.TemperatureUnit !==
      //   initialFormValues.current.TemperatureUnit ||
      // userProfileValues.WaterUnit !== initialFormValues.current.WaterUnit ||
      // userProfileValues.ClockType !== initialFormValues.current.ClockType
    ) {
      setDisableSaveChanges(false);
    }

    if (
      userProfileValues?.FirstName === initialFormValues.current.FirstName &&
      userProfileValues?.LastName === initialFormValues.current.LastName &&
      userProfileValues?.PhoneNumber === initialFormValues.current.PhoneNumber
      // userProfileValues.Language === initialFormValues.current.Language &&
      // userProfileValues.TemperatureUnit ===
      //   initialFormValues.current.TemperatureUnit &&
      // userProfileValues.WaterUnit === initialFormValues.current.WaterUnit &&
      // userProfileValues.ClockType === initialFormValues.current.ClockType
    ) {
      setDisableSaveChanges(true);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const userProfileValues = userBasicsForm.getFieldsValue();
    const request = {
      firstName: userProfileValues.FirstName,
      lastName: userProfileValues.LastName,
      phoneNumber: userProfileValues.PhoneNumber,
      language: userProfileValues.Language,
      imageUrl: initialFormValues.current?.ImageUrl, // keep initialFormValues.current.ImageUrl so that we do not lose the avatar.
      waterUnit: "Litres".toLowerCase(), // TBD - set from userProfileValues.
      temperatureUnit: "Celsius".toLowerCase(), // TBD - set from userProfileValues.
      // clockType: userProfileValues.ClockType,
    };
    updateUserBasicsV2(request, {
      onSuccess: (response) => {
        userBasicsForm.setFieldsValue(response.data);
        initialFormValues.current = response.data;
        setDisableSaveChanges(true);
        setIsLoading(false);
      },
      onError: () => {
        setIsLoading(false);
        notification.error({
          message: (
            <span className="uppercase tracking-widest">
              Something went wrong!
            </span>
          ),
          duration: 0,
          placement: "topRight",
          icon: <FrownOutlined className="text-triple-red" />,
        });
      },
    });
  };

  const filterSelect = (input, option) =>
    (option?.label.props.children[1] ?? "")
      .toLowerCase()
      .includes(input.toLowerCase());

  return (
    <div className="content-wrapper overflow-auto">
      <Form
        form={userBasicsForm}
        name="update-user-profile"
        layout="vertical"
        className="w-full"
        initialValues={{
          FirstName: initialFormValues.current?.FirstName,
          LastName: initialFormValues.current?.LastName,
          Email: initialFormValues.current?.Email,
          PhoneNumber: initialFormValues.current?.PhoneNumber,
          Language: initialFormValues.current?.Language
            ? initialFormValues.current?.Language
            : "EN",
          WaterUnit: initialFormValues.current?.WaterUnit
            ? initialFormValues.current?.WaterUnit
            : "litres",
          TemperatureUnit: initialFormValues.current?.TemperatureUnit
            ? initialFormValues.current?.TemperatureUnit
            : "celsius",
          ClockType: initialFormValues.current?.ClockType
            ? initialFormValues.current?.ClockType
            : "24",
        }}
        onFinish={handleSubmit}
        onFieldsChange={handleFieldsChanges}
      >
        <div className="flex items-center justify-between mb-0">
          <h1 className="page-title">Profile</h1>
          <div className="ml-auto inline-flex items-center">
            <SaveChangesButton
              className="xxs:w-full hidden sm:flex"
              isLoading={isLoading}
              disableSaveChanges={disableSaveChanges}
            />
          </div>
        </div>
        <div className="w-full md:w-1/4">
          <Form.Item
            className="mb-4"
            label="First Name"
            name="FirstName"
            rules={[
              { required: true, message: "Please input your First Name" },
            ]}
          >
            <Input size="large" maxLength={64} />
          </Form.Item>

          <Form.Item
            className="mb-4"
            label="Last Name"
            name="LastName"
            rules={[{ required: true, message: "Please input your Last Name" }]}
          >
            <Input size="large" maxLength={64} />
          </Form.Item>

          <Form.Item className="mb-4" label="Email" name="Email">
            <Input size="large" disabled />
          </Form.Item>

          <Form.Item className="mb-4" label="Phone Number" name="PhoneNumber">
            <Input size="large" maxLength={15} />
          </Form.Item>

          <Form.Item className="mb-4" label="Language" name="Language">
            <Select
              placeholder="Languages"
              filterOption={filterSelect}
              size="large"
              options={[
                {
                  value: "EN",
                  label: (
                    <>
                      <CountryFlags.USA className="mr-2 mb-1 align-middle" />
                      English
                    </>
                  ),
                },
              ]}
            />
          </Form.Item>

          {/* 
          <Toggle
            fieldLabel="Water Measure Units"
            fieldName="WaterUnit"
            fieldValues={[
              { value: "litres", label: "Litres" },
              { value: "gallons", label: "Gallons" },
            ]}
            className="mb-4"
          />

          <Toggle
            fieldLabel="Temperature Measure Units"
            fieldName="TemperatureUnit"
            fieldValues={[
              { value: "celsius", label: "Celsius" },
              { value: "fahrenheit", label: "Fahrenheit" },
            ]}
            className="mb-4"
          />

          <Toggle
            fieldLabel="Clock"
            fieldName="ClockType"
            fieldValues={[
              { value: "24", label: "24 hr time" },
              { value: "12", label: "12 hr time" },
            ]}
          />
            */}

          <SaveChangesButton
            className="w-full mobile-save-changes block sm:hidden"
            isLoading={isLoading}
            disableSaveChanges={disableSaveChanges}
          />
        </div>
      </Form>
    </div>
  );
}

export default Profile;
