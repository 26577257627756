import PropTypes from "prop-types";

import CustomTable from "../../CustomTable/CustomTable";

const propTypes = {
  data: PropTypes.array,
  status: PropTypes.string,
  pageSize: PropTypes.number,
  totalCount: PropTypes.number,
  onChange: PropTypes.func,
};

const defaultProps = {
  data: [],
  status: "loading",
  pageSize: 10,
  totalCount: 0,
  onChange: () => {},
};

const getActionInfo = (customFlowClosedata) => {
  const alarmString = "Alarm";
  const shutOffString = "ShutOff";

  switch (Number(customFlowClosedata)) {
    case 0:
      return alarmString;
    case 1:
      return shutOffString;
    default:
      return "-";
  }
};

function FdsScheduleListTable({
  data,
  status,
  pageSize,
  totalCount,
  onChange,
}) {
  const columns = [
    {
      title: "Property Name",
      key: "PropertyName",
      render: (record) => {
        return (
          <span className="text-white text-opacity-80 font-light">
            {record?.PropertyName || "-"}
          </span>
        );
      },
      width: 200,
    },
    {
      title: "Floor",
      key: "FloorName",
      render: (record) => {
        return (
          <span className="text-white text-opacity-80 font-light">
            {record?.FloorName || "-"}
          </span>
        );
      },
      width: 200,
    },
    {
      title: "System",
      key: "SystemName",
      render: (record) => {
        return (
          <span className="text-white text-opacity-80 font-light">
            {record?.SystemName || "-"}
          </span>
        );
      },
      width: 200,
    },
    {
      title: "Device Type",
      key: "DeviceType",
      render: (record) => {
        return (
          <span className="text-white text-opacity-80 font-light">
            {record?.DeviceType || "-"}
          </span>
        );
      },
      width: 200,
    },
    {
      title: "Serial Number",
      key: "DeviceSerialNumber",
      render: (record) => {
        return (
          <span className="text-white text-opacity-80 font-light">
            {record?.DeviceSerialNumber || "-"}
          </span>
        );
      },
      width: 175,
    },
    {
      title: "Flow Rate",
      key: "FlowRate",
      render: () => {
        return (
          <span className="text-white text-opacity-80 font-light">
            High/Medium/Low
          </span>
        );
      },
      width: 140,
    },
    {
      title: "Flow duration",
      key: "FlowDuration",
      render: (record) => {
        const lowDuration = record?.CustomFlow?.Data?.LowDuration || "-";
        const midDuration = record?.CustomFlow?.Data?.MedDuration || "-";
        const highDuration = record?.CustomFlow?.Data?.HighDuration || "-";
        return (
          <span className="text-white text-opacity-80 font-light">
            {`${highDuration} / ${midDuration} / ${lowDuration}`}
          </span>
        );
      },
      width: 150,
    },
    {
      title: "Action",
      key: "Action",
      render: (record) => {
        const lowClose = record?.CustomFlow?.Data?.LowClose ?? "-";
        const midClose = record?.CustomFlow?.Data?.MedClose ?? "-";
        const highClose = record?.CustomFlow?.Data?.HighClose ?? "-";

        const lowCloseInfo = getActionInfo(lowClose);
        const midCloseInfo = getActionInfo(midClose);
        const highCloseInfo = getActionInfo(highClose);

        return (
          <span className="text-white text-opacity-80 font-light">
            {`${highCloseInfo}/${midCloseInfo}/${lowCloseInfo}`}
          </span>
        );
      },
      width: 250,
    },
  ];

  return (
    <CustomTable
      className={`${!pageSize ? "opacity-0" : "opacity:100"}`}
      data={data}
      status={status}
      autoRows
      noPagination={!(totalCount > pageSize) || status === "loading"}
      tableColumns={columns}
      totalCount={totalCount}
      onChange={onChange}
    />
  );
}

FdsScheduleListTable.propTypes = propTypes;
FdsScheduleListTable.defaultProps = defaultProps;

export default FdsScheduleListTable;
