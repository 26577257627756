import { useEffect, useRef, useState } from "react";

import { FrownOutlined } from "@ant-design/icons";
import { Form, Input, Select, TimePicker, notification } from "antd";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";

import usePropertyPreferences from "../../../api/hooks/usePropertyPreferences";
import useUpdatePropertyPreferences from "../../../api/hooks/useUpdatePropertyPreferences";
// import CurrencyOptions from "../../../constants/CurrencyOptions";
import DateConstants from "../../../constants/DateConstants";
import RegularExpressions from "../../../constants/RegExpressions";
import SaveChanges from "../../Shared/SaveChangesButton";
import Toggle from "../../Shared/Toggle";
import "./PropertyPreferences.scss";

function PropertyPreferences() {
  const [isLoading, setIsLoading] = useState(false);
  const [disableSaveChanges] = useState(false);
  const [showWorkingDaysRoutine, setShowWorkingDaysRoutine] = useState(false);

  const [propertyPreferencesForm] = Form.useForm();
  const initialFormValues = useRef({});

  const { propertyId } = useParams();
  const { mutate: updatePropertyPreferences } =
    useUpdatePropertyPreferences(propertyId);
  const { refetch: getPropertyPreferences } =
    usePropertyPreferences(propertyId);

  useEffect(() => {
    getPropertyPreferences(propertyId).then((response) => {
      initialFormValues.current.Name = response.data.Name;
      initialFormValues.current.Address = response.data.Address;
      initialFormValues.current.ImageUrl = response.data.ImageUrl;
      initialFormValues.current.NumberOfOccupants =
        response.data.PropertyPreferences.NumberOfOccupants;
      initialFormValues.current.CostOfWater =
        response.data.PropertyPreferences.CostOfWater;
      initialFormValues.current.Currency =
        response.data.PropertyPreferences.Currency;
      initialFormValues.current.WorkingDaysEnabled =
        response.data.PropertyPreferences.WorkingDaysEnabled;
      initialFormValues.current.WorkingDaysStartDay =
        response.data.PropertyPreferences.WorkingDaysStartDayOfWeek;
      initialFormValues.current.WorkingDaysEndDay =
        response.data.PropertyPreferences.WorkingDaysEndDayOfWeek;
      initialFormValues.current.WorkingDaysStartTime = dayjs(
        new Date(
          response.data.PropertyPreferences.WorkingDaysStartTime,
        ).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        }),
        "HH:mm",
      );
      initialFormValues.current.WorkingDaysEndTime = dayjs(
        new Date(
          response.data.PropertyPreferences.WorkingDaysEndTime,
        ).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        }),
        "HH:mm",
      );
      propertyPreferencesForm.setFieldsValue(initialFormValues.current);
      if (initialFormValues.current.WorkingDaysEnabled) {
        setShowWorkingDaysRoutine(true);
      }
    });
  }, [getPropertyPreferences, propertyId, propertyPreferencesForm]);

  const clearFormFieldError = (fieldName) => {
    propertyPreferencesForm.setFields([
      {
        name: fieldName,
        errors: [],
      },
    ]);
  };

  const setFormFieldError = (fieldName, errorMsg) => {
    propertyPreferencesForm.setFields([
      {
        name: fieldName,
        errors: [errorMsg],
      },
    ]);
  };

  const handleFieldsChanges = (changedFields) => {
    const fieldName = changedFields[0].name[0];
    const fieldValue = changedFields[0].value;

    if (fieldName === "NumberOfOccupants") {
      if (fieldValue !== "") {
        const isValidInput =
          RegularExpressions.PositiveNegativeNumbers.test(fieldValue);
        if (isValidInput) {
          if (fieldValue < 0) {
            setFormFieldError(
              "NumberOfOccupants",
              "Number of Occupants cannot be a negative number.",
            );
          } else {
            clearFormFieldError("NumberOfOccupants");
          }
        } else {
          setFormFieldError(
            "NumberOfOccupants",
            "Number of Occupants is invalid.",
          );
        }
      } else {
        clearFormFieldError("NumberOfOccupants");
      }
    }

    if (fieldName === "CostOfWater") {
      if (fieldValue !== "") {
        const isValidInput =
          RegularExpressions.PositiveNegativeNumbersWithDecimal.test(
            fieldValue,
          );
        if (isValidInput) {
          if (fieldValue < 0) {
            setFormFieldError(
              "CostOfWater",
              "Cost of Water cannot be a negative number.",
            );
          } else {
            clearFormFieldError("CostOfWater");
          }
        } else {
          setFormFieldError("CostOfWater", "Cost Of Water is invalid.");
        }
      } else {
        clearFormFieldError("CostOfWater");
      }
    }

    if (fieldName === "WorkingDaysEnabled") {
      if (fieldValue === true) {
        setShowWorkingDaysRoutine(true);
      } else {
        setShowWorkingDaysRoutine(false);
      }
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const formValues = propertyPreferencesForm.getFieldsValue();
    const request = {
      Id: propertyId,
      Name: formValues.Name,
      Address: formValues.Address,
      ImageUrl: initialFormValues.current.ImageUrl, // Keep the original ImageUrl for now
      PropertyPreferences: {
        NumberOfOccupants: formValues.NumberOfOccupants,
        CostOfWater: formValues.CostOfWater ?? 0,
        Currency: formValues.Currency ?? "USD",
        WorkingDaysEnabled: formValues.WorkingDaysEnabled,
        WorkingDaysStartDayOfWeek: formValues.WorkingDaysStartDay
          ? formValues.WorkingDaysStartDay
          : initialFormValues.current.WorkingDaysStartDay,
        WorkingDaysEndDayOfWeek: formValues.WorkingDaysEndDay
          ? formValues.WorkingDaysEndDay
          : initialFormValues.current.WorkingDaysEndDay,
        WorkingDaysStartTime: formValues.WorkingDaysStartTime
          ? formValues.WorkingDaysStartTime.format("HH:mm")
          : initialFormValues.current.WorkingDaysStartTime.format("HH:mm"),
        WorkingDaysEndTime: formValues.WorkingDaysEndTime
          ? formValues.WorkingDaysEndTime.format("HH:mm")
          : initialFormValues.current.WorkingDaysEndTime.format("HH:mm"),
      },
    };

    updatePropertyPreferences(request, {
      onSuccess: () => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      },
      onError: (response) => {
        setIsLoading(false);
        notification.error({
          message: (
            <span className="uppercase tracking-widest">
              Something went wrong!
            </span>
          ),
          duration: 5,
          description: response.message,
          placement: "topRight",
          icon: <FrownOutlined className="text-triple-red" />,
        });
      },
    });
  };
  return (
    <div className="content-wrapper overflow-y-auto">
      <Form
        form={propertyPreferencesForm}
        name="update-property-preferences"
        layout="vertical"
        className="w-full"
        initialValues={{
          Name: initialFormValues.current.Name,
          Address: initialFormValues.current.Address,
          NumberOfOccupants: initialFormValues.current?.NumberOfOccupants,
          CostOfWater: initialFormValues.current?.CostOfWater,
          Currency: initialFormValues.current?.Currency
            ? initialFormValues.current?.Currency
            : "USD",
          WorkingDaysEnabled: initialFormValues.current?.WorkingDaysEnabled,
          WorkingDaysStartDay: initialFormValues.current?.WorkingDaysStartDay,
          WorkingDaysEndDay: initialFormValues.current?.WorkingDaysEndDay,
          WorkingDaysStartTime: initialFormValues.current?.WorkingDaysStartTime
            ? dayjs(initialFormValues.current?.WorkingDaysStartTime, "HH:mm")
            : "",
          WorkingDaysEndTime: initialFormValues.current?.WorkingDaysEndTime
            ? dayjs(initialFormValues.current?.WorkingDaysEndTime, "HH:mm")
            : "",
        }}
        onFinish={handleSubmit}
        onFieldsChange={handleFieldsChanges}
      >
        <div className="flex items-center justify-between mb-0">
          <h1 className="page-title">Property Preferences</h1>
          <div className="ml-auto inline-flex items-center">
            <SaveChanges
              className="xxs:w-full hidden sm:flex"
              isLoading={isLoading}
              disableSaveChanges={disableSaveChanges}
            />
          </div>
        </div>
        <div className="w-full md:w-1/4">
          <Form.Item
            className="mb-4"
            label="Name"
            name="Name"
            rules={[{ required: true, message: "Name field is required." }]}
          >
            <Input size="large" maxLength={64} />
          </Form.Item>
          <Form.Item className="mb-4" label="Address" name="Address">
            <Input size="large" maxLength={128} />
          </Form.Item>
          <Form.Item
            className="mb-4"
            label="Number of Occupants"
            name="NumberOfOccupants"
          >
            <Input size="large" maxLength={10} />
          </Form.Item>
          {/* <Form.Item className="mb-4" label="Cost of Water" name="CostOfWater">
            <Input size="large" maxLength={10} />
          </Form.Item>
          <Form.Item className="mb-4" label="Currency" name="Currency">
            <Select
              placeholder="Currency"
              options={CurrencyOptions}
              size="large"
            />
          </Form.Item> */}
          <Toggle
            className="mb-4"
            fieldLabel="Working Days Routine"
            fieldName="WorkingDaysEnabled"
            fieldValues={[
              {
                value: false,
                label: "Disable",
              },
              {
                value: true,
                label: "Enable",
              },
            ]}
          />

          {showWorkingDaysRoutine === true ? (
            <>
              <p>Working Days Routine Setup</p>
              <Form.Item className="mb-0">
                <Form.Item
                  className="mx-2 inline-block"
                  label=""
                  name="WorkingDaysStartDay"
                >
                  <Select
                    placeholder="Start Day"
                    className="working-days-picker"
                    options={Object.values(
                      DateConstants.fullyQualifiedDaysOfWeek,
                    ).map((day, i) => {
                      return { value: i, label: day };
                    })}
                  />
                </Form.Item>
                <span className="divider-To">To</span>
                <Form.Item
                  className="mx-2 inline-block"
                  label=""
                  name="WorkingDaysEndDay"
                >
                  <Select
                    placeholder="End Day"
                    className="working-days-picker"
                    options={Object.values(
                      DateConstants.fullyQualifiedDaysOfWeek,
                    ).map((day, i) => {
                      return { value: i, label: day };
                    })}
                  />
                </Form.Item>
              </Form.Item>

              <Form.Item>
                <Form.Item
                  className="mx-2 inline-block"
                  label=""
                  name="WorkingDaysStartTime"
                >
                  <TimePicker
                    className="working-days-timepicker"
                    format="HH:mm"
                    inputReadOnly
                    placeholder="Start Time"
                    size="large"
                    showNow={false}
                  />
                </Form.Item>
                <span className="divider-To">To</span>
                <Form.Item
                  className="mx-2 inline-block"
                  label=""
                  name="WorkingDaysEndTime"
                >
                  <TimePicker
                    className="working-days-timepicker"
                    format="HH:mm"
                    inputReadOnly
                    placeholder="End Time"
                    size="large"
                    showNow={false}
                  />
                </Form.Item>
              </Form.Item>
            </>
          ) : null}
          <SaveChanges
            className="w-full mobile-save-changes block sm:hidden"
            isLoading={isLoading}
            disableSaveChanges={disableSaveChanges}
          />
        </div>
      </Form>
    </div>
  );
}

export default PropertyPreferences;
