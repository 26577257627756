import { Button, Form } from "antd";
import PropTypes from "prop-types";

function SaveChanges({ className, isLoading, disableSaveChanges }) {
  return (
    <Form.Item className={className}>
      <Button
        type="primary"
        htmlType="submit"
        size="large"
        className="xxs:w-full"
        loading={isLoading}
        disabled={disableSaveChanges}
      >
        Save Changes
      </Button>
    </Form.Item>
  );
}

SaveChanges.defaultProps = {
  className: "",
  disableSaveChanges: true,
  isLoading: false,
};
SaveChanges.propTypes = {
  className: PropTypes.string,
  disableSaveChanges: PropTypes.bool,
  isLoading: PropTypes.bool,
};
export default SaveChanges;
