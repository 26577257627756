import { ExclamationCircleFilled } from "@ant-design/icons";
import { Divider } from "antd";
import PropTypes from "prop-types";

import { HubIcon } from "../../../assets/icons/systems";

const propTypes = {
  className: PropTypes.string,
  property: PropTypes.object.isRequired,
};

const defaultProps = {
  className: "",
};

function PropertyDetails({ className, property }) {
  return (
    <div className={`property-details ${className}`}>
      <h3 className="property-details-title">{property?.Name}</h3>
      <div className="property-details-stats">
        <div className="property-details-systems">
          <span className="font-light">
            <HubIcon className="align-sub" />
            <span className="inline-block text-lg xl:text-2xl mx-1 xl:mx-2">
              {property?.SystemsCount}
            </span>
            <span className="hidden xxl:inline-block">Hubs</span>
          </span>
        </div>

        <Divider type="vertical" className="xl:mx-4" />

        <div className="property-details-alerts">
          <ExclamationCircleFilled className="text-triple-red text-md xl:text-xl" />
          <span className="font-light">
            <span className="inline-block text-lg xl:text-2xl mx-1 xl:mx-2">
              {property?.AlertsCount}
            </span>
            <span className="hidden xxl:inline-block">Alerts</span>
          </span>
        </div>
      </div>
    </div>
  );
}

PropertyDetails.propTypes = propTypes;
PropertyDetails.defaultProps = defaultProps;

export default PropertyDetails;
