import { useEffect, useState } from "react";

import { FrownOutlined } from "@ant-design/icons";
import { Skeleton, Pagination } from "antd";
import Search from "antd/es/input/Search";
import { useSearchParams } from "react-router-dom";

import usePropertiesCount from "../../../api/hooks/usePropertiesCount";
import usePropertyBasics from "../../../api/hooks/usePropertyBasics";
import useWindowSize from "../../../hooks/useWindowSize";
import PropertyManagementCard from "./PropertyManagementCard";

function PropertyManagement() {
  const window = useWindowSize();
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");

  const pageSize = 20;
  const [searchParams, setSearchParams] = useSearchParams();

  // get search params from URl and format them as expected from the API
  const params = {};

  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  const { page } = params;

  const onSearch = (value) => {
    setSearchQuery(value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  const { data: totalPropertiesCount } = usePropertiesCount({
    search: debouncedSearchQuery,
  });

  const { data: properties, status: loadingStatus } = usePropertyBasics({
    pageSize,
    startIndex: Number(page) - 1 >= 0 ? (Number(page) - 1) * pageSize : 0,
    search: debouncedSearchQuery,
  });

  return (
    <div className="property-list content-wrapper overflow-y-auto">
      <div className="flex md:items-center mb-6 flex-col-reverse md:flex-row">
        <div className="flex-1 mt-6 md:mt-0 flex lg:items-center flex-col lg:flex-row">
          {window?.width > 768 && (
            <h1 className="page-title mb-0">Property Management</h1>
          )}
        </div>

        <Search
          className="w-full md:w-fit mr-4"
          placeholder="Search Properties"
          onSearch={onSearch}
          onChange={handleSearchChange}
          allowClear
          size="large"
        />
      </div>

      {loadingStatus === "error" && (
        <div className="p-6 text-white text-opacity-80 text-center">
          <FrownOutlined className="text-xl mr-2" />
          Something went wrong
        </div>
      )}

      {loadingStatus === "loading" &&
        Array.from({ length: pageSize }, (value, index) => index).map((el) => (
          <Skeleton
            active
            paragraph={{
              rows: 3,
            }}
            key={el}
          />
        ))}

      {loadingStatus === "success" && properties?.length === 0 && (
        <div className="p-6 text-white text-opacity-80">
          <FrownOutlined className="text-xl mr-2" />
          We couldn`t find any matches
        </div>
      )}

      {loadingStatus === "success" && properties?.length > 0 && (
        <div className="sm:grid sm:grid-cols-2 lg:grid-cols-1 gap-x-6 lg:gap-x-0 mb-6">
          {properties
            ?.sort(
              (firstElement, secondElement) =>
                firstElement.Id - secondElement.Id,
            )
            .map((property) => (
              <PropertyManagementCard key={property.Id} property={property} />
            ))}
        </div>
      )}

      {loadingStatus === "success" &&
        properties?.length > 0 &&
        totalPropertiesCount > pageSize && (
          <Pagination
            defaultCurrent={1}
            pageSize={pageSize}
            total={totalPropertiesCount}
            current={Number(searchParams.get("page")) || 1}
            showSizeChanger={false}
            size={window?.width <= 576 && "small"}
            onChange={(page) => {
              setSearchParams({ page });
            }}
          />
        )}
    </div>
  );
}

export default PropertyManagement;
