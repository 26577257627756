import { useRef, useState } from "react";

import { FrownOutlined } from "@ant-design/icons";
import { Alert, Col, Form, Input, Row, notification } from "antd";
import { useNavigate } from "react-router-dom";

import useCreateMonitorStationOperators from "../../../api/hooks/useMonitorStationOperatorsCreate";
import usePropertiesWithSystems from "../../../api/hooks/usePropertiesWithSystems";
import RegularExpressions from "../../../constants/RegExpressions";
import Loader from "../../Loader/Loader";
import BackButton from "../../Shared/BackButton";
import SaveChanges from "../../Shared/SaveChangesButton";
import Permission from "./Permission/Permission";

function CreateUser() {
  const [isLoading, setIsLoading] = useState(false);
  const [disableSaveChanges, setDisableSaveChanges] = useState(true);
  const [createUserForm] = Form.useForm();
  const initialFormValues = useRef({});
  const systemsPermission = useRef([]);
  const navigate = useNavigate();

  initialFormValues.current = {
    FirstName: "",
    LastName: "",
    Email: "",
    PhoneNumber: "",
    ApplyToAll: true,
    Permission: "ReadOnly",
    SystemPermissions: [],
  };
  const confirmationTitle = "Are you sure want to leave the current page?";
  const confirmationMessage = "The changes that you made will NOT be saved.";
  const { mutate: createMonitorStationOperators } =
    useCreateMonitorStationOperators();

  const { data: propertiesWithSystems, status } = usePropertiesWithSystems();

  const handleFieldsChange = (changedFields) => {
    const createUserFormValues = createUserForm.getFieldsValue();
    const fieldName = changedFields[0]?.name[0];
    const fieldValue = changedFields[0]?.value;

    if (fieldName === "PhoneNumber") {
      if (fieldValue === "") {
        setDisableSaveChanges(true);
        createUserForm.setFields([
          {
            name: "PhoneNumber",
            errors: ["Phone Number field is required."],
          },
        ]);
      } else {
        const isValidInput = RegularExpressions.NumbersOnly.test(fieldValue);
        if (isValidInput) {
          createUserForm.setFields([{ name: "PhoneNumber", errors: [] }]);
        } else {
          setDisableSaveChanges(true);
          createUserForm.setFields([
            {
              name: "PhoneNumber",
              errors: ["Phone Number should contain only digits."],
            },
          ]);
        }
      }
    }

    if (
      createUserFormValues?.FirstName !== initialFormValues.current.FirstName ||
      createUserFormValues?.LastName !== initialFormValues.current.LastName ||
      createUserFormValues?.PhoneNumber !==
        initialFormValues.current.PhoneNumber ||
      createUserFormValues?.Email !== initialFormValues.current.Email
    ) {
      setDisableSaveChanges(false);
    }

    if (
      createUserFormValues?.FirstName === initialFormValues.current.FirstName &&
      createUserFormValues?.LastName === initialFormValues.current.LastName &&
      createUserFormValues?.PhoneNumber ===
        initialFormValues.current.PhoneNumber &&
      createUserFormValues?.Email === initialFormValues.current.Email
    ) {
      setDisableSaveChanges(true);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const createUserFormValues = createUserForm.getFieldsValue();
    const { ApplyToAll, Permission } = createUserFormValues;
    let allSystems;
    if (ApplyToAll) {
      allSystems = propertiesWithSystems
        .flatMap((p) => p.Floors)
        .flatMap((f) => f.Systems)
        .flatMap((s) => s.UniqueId)
        .map((systemUniqueId) => {
          return {
            SystemUniqueId: systemUniqueId,
            PermissionType: Permission,
          };
        });
    }
    const request = {
      Username: createUserFormValues.Email,
      FirstName: createUserFormValues.FirstName,
      LastName: createUserFormValues.LastName,
      PhoneNumber: createUserFormValues.PhoneNumber,
      SystemPermissions:
        ApplyToAll === true ? allSystems : systemsPermission.current,
    };

    createMonitorStationOperators(request, {
      onSuccess: () => {
        navigate("/users");
      },
      onError: () => {
        setIsLoading(false);
        notification.error({
          message: (
            <span className="uppercase tracking-widest">
              Something went wrong!
            </span>
          ),
          duration: 0,
          placement: "topRight",
          icon: <FrownOutlined className="text-triple-red" />,
        });
      },
    });
  };

  if (status === "loading") {
    <Loader className="absolute-center" />;
  }

  if (status === "error") {
    <div className="flex items-center justify-center">
      <Alert
        message="We have bad news"
        description="Еither we broke something or there is nothing to display."
        type="error"
        showIcon
        icon={<FrownOutlined />}
        className="w-fit"
      />
    </div>;
  }

  if (status === "success") {
    return (
      <div className="content-wrapper overflow-y-auto">
        <Form
          form={createUserForm}
          name="create-user-form"
          layout="vertical"
          className="w-full"
          onFinish={handleSubmit}
          onFieldsChange={handleFieldsChange}
          initialValues={initialFormValues.current}
        >
          <div className="inline">
            <BackButton
              redirect={{ to: "/users" }}
              confirmation
              confirmationMessage={confirmationMessage}
              confirmationTitleMessage={confirmationTitle}
            />
          </div>
          <div className="flex items-center justify-between mb-0">
            <h1 className="page-title">create new user</h1>
            <div className="ml-auto inline-flex items-center">
              <SaveChanges
                className="xxs:w-full hidden sm:flex"
                isLoading={isLoading}
                disableSaveChanges={disableSaveChanges}
              />
            </div>
          </div>
          <Row gutter={24}>
            <Col span={12} xs={24} md={12}>
              <Form.Item
                className="mb-4"
                label="First Name"
                name="FirstName"
                rules={[
                  { required: true, message: "First Name field is required." },
                ]}
              >
                <Input size="large" maxLength={100} />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} md={12}>
              <Form.Item
                className="mb-4"
                label="Last Name"
                name="LastName"
                rules={[
                  { required: true, message: "Last Name field is required." },
                ]}
              >
                <Input size="large" maxLength={100} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12} xs={24} md={12}>
              <Form.Item
                className="mb-4"
                label="Email"
                name="Email"
                rules={[
                  { required: true, message: "Email field is required." },
                  { type: "email", message: "Invalid email address." },
                ]}
              >
                <Input size="large" maxLength={100} />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} md={12}>
              <Form.Item
                className="mb-4"
                label="Phone Number"
                name="PhoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Phone Number field is required.",
                  },
                ]}
              >
                <Input size="large" maxLength={15} />
              </Form.Item>
            </Col>
          </Row>
          <Permission
            data={propertiesWithSystems}
            formRef={createUserForm}
            systemsPermissionRef={systemsPermission}
          />
          <SaveChanges
            className="w-full mobile-save-changes block sm:hidden"
            isLoading={isLoading}
            disableSaveChanges={disableSaveChanges}
          />
        </Form>
      </div>
    );
  }
}

export default CreateUser;
