import { useEffect, useState } from "react";

import { Col, Row } from "antd";
import { Outlet } from "react-router-dom";

import useStatisticsAnnualWaterConsumption from "../../api/hooks/useStatisticsAnnualWaterConsumption";
import Loader from "../Loader/Loader";
import StatisticsCalendar from "./Calendar/StatisticsCalendar";
import StatisticsChart from "./Charts/StatisticsCharts";
import StatisticsConsumptionCards from "./ConsumptionCards/StatisticsConsumptionCards";
import StatisticsFilters from "./Filters/StatisticsFilters";
import StatisticsPropertyOverview from "./PropertyOverview/StatisticsPropertyOverview";
import "./Statistics.scss";

function Statistics() {
  const [property, setPropertyId] = useState(null);
  const [floorId, setFloorId] = useState(null);
  const [systemId, setSystemId] = useState(null);
  const [date, setDate] = useState();
  const [calendarDateValue, setCalendarDateValue] = useState({});
  const handleOnChangeFilterCallback = (property, floorId, systemId) => {
    setPropertyId(property);
    setFloorId(floorId);
    setSystemId(systemId);
  };

  const handleOnChangeCalendarDateCallback = (date) => {
    setDate(date);
  };

  // GET api/Statistics/WaterConsumption/Annual/{year}?propertyId=&floorId=&systemId=
  const {
    data: waterConsumptionData,
    status,
    refetch: getAnnualWaterConsumption,
  } = useStatisticsAnnualWaterConsumption(
    date?.year,
    {
      propertyId: property?.PropertyId,
      floorId,
      systemId,
    },
    { enabled: false, cacheTime: 0 },
  );

  useEffect(() => {
    if (date) {
      getAnnualWaterConsumption(date.year, {
        propertyId: property?.PropertyId,
        floorId,
        systemId,
      });
    }
  }, [
    property,
    floorId,
    systemId,
    date,
    waterConsumptionData,
    getAnnualWaterConsumption,
  ]);

  return (
    <div className="statistics">
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={24} lg={9} xl={7} xxl={6}>
          <StatisticsPropertyOverview propertyOverview={property} />
          <StatisticsFilters
            handleOnChangeFilterCallback={handleOnChangeFilterCallback}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={15} xl={17} xxl={18}>
          <StatisticsConsumptionCards
            waterConsumptionData={waterConsumptionData}
          />
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={24} lg={9} xl={7} xxl={6}>
          <StatisticsCalendar
            handleOnChangeCalendarDateCallback={
              handleOnChangeCalendarDateCallback
            }
            calendarDateValue={calendarDateValue}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={15} xl={17} xxl={18}>
          {status === "loading" ? (
            <div className="flex justify-center mt-10">
              <Loader />
            </div>
          ) : (
            <StatisticsChart
              statisticsfilters={{
                propertyId: property?.PropertyId,
                floorId,
                systemId,
              }}
              waterConsumptionData={waterConsumptionData}
              setCalendarDateValue={setCalendarDateValue}
            />
          )}
        </Col>
      </Row>
      <Outlet />
    </div>
  );
}

export default Statistics;
