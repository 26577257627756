import { useEffect, useState } from "react";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Layout } from "antd";
import { Outlet, useRoutes } from "react-router-dom";

import useUser from "./api/hooks/useUser";
import PersistLogin from "./auth/PersistLogin";
import RequireAdmin from "./auth/RequireAdmin";
import RequireAuth from "./auth/RequireAuth";
import AlarmDetails from "./components/Dashboard/Alarms/AlarmDetails/AlarmDetails";
import AlarmModal from "./components/Dashboard/Alarms/AlarmModal/AlarmModal";
import Dashboard from "./components/Dashboard/Dashboard";
import Forbidden from "./components/ErrorPages/Forbidden";
import PageNotFound from "./components/ErrorPages/PageNotFound";
import EventDetails from "./components/Events/EventDetails/EventDetails";
import Events from "./components/Events/Events";
import EventsFilter from "./components/Events/EventsFilter/EventsFilter";
import CreateFlowConfiguration from "./components/FdsSchedulesList/CreateFlowConfiguration/CreateFlowConfiguration";
import FDSSchedulesList from "./components/FdsSchedulesList/FdsSchedulesList";
import CmsFooter from "./components/Footer/CmsFooter";
import Header from "./components/Header/Header";
import LoginPage from "./components/LoginPge/LoginPage";
import PropertyManagement from "./components/Preferences/PropertyManagement/PropertyManagement";
import PropertyPreferences from "./components/Preferences/PropertyManagement/PropertyPreferences";
import CreateUser from "./components/Preferences/Users/CreateUser";
import UsersList from "./components/Preferences/Users/UsersList";
import Profile from "./components/Profile/Profile";
import FloorList from "./components/PropertyOverview/FloorList/FloorList";
import FloorSystemDeviceDetails from "./components/PropertyOverview/FloorSystemDeviceDetails/FloorSystemDeviceDetails";
import FloorSystemsTableMobile from "./components/PropertyOverview/FloorSystemsTable/FloorSystemsTableMobile";
import PropertyList from "./components/PropertyOverview/PropertyList/PropertyList";
import CreateNewSchedule from "./components/Schedule/CreateNewSchedule/CreateNewSchedule";
import Schedule from "./components/Schedule/Schedule";
import ScheduleCalendar from "./components/Schedule/ScheduleCalendar/ScheduleCalendar";
import Sidebar from "./components/Sidebar/Sidebar";
import Statistics from "./components/Statistics/Statistics";
import { FdsFiltersValuesProvider } from "./context/FdsFiltersValuesProvider";
import { ScheduleSystemsProvider } from "./context/ScheduleSystemsProvider";
import useAuth from "./hooks/useAuth";
import useWindowSize from "./hooks/useWindowSize";
import ThemeConfigProvider from "./theme/ThemeConfigProvider";
import "./App.scss";

function App() {
  const [isMobileNavActive, setIsMobileNavActive] = useState(false);

  const { auth, setAuth } = useAuth();
  const { refetch: getLoggedUser } = useUser();
  const window = useWindowSize();

  const { Sider, Content } = Layout;

  // get the logged user and add it to the auth context
  useEffect(() => {
    if (auth.isAuthenticated && !auth.user) {
      getLoggedUser().then((res) => {
        setAuth((prevState) => ({ ...prevState, user: res.data }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuthenticated]);

  // remove scrollbar when the mobile nav is active
  useEffect(() => {
    if (isMobileNavActive) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [window, isMobileNavActive]);

  // app routing
  const routes = [
    {
      path: "/",
      element: (
        <PersistLogin>
          <RequireAuth>
            <>
              <Header
                withNavigation
                isMobileNavActive={isMobileNavActive}
                setIsMobileNavActive={setIsMobileNavActive}
              />

              <div className="flex min-h-0 h-full">
                {/* Desktop sidebar */}
                {window?.width > 992 && (
                  <Sider className="sidebar-wrapper">
                    <Sidebar className="sidebar" />
                  </Sider>
                )}

                {/* Mobile nav */}
                {window?.width <= 992 && isMobileNavActive && (
                  <div
                    role="none"
                    onClick={(e) =>
                      e.target.classList.contains("nav-link") &&
                      setIsMobileNavActive(false)
                    }
                    className="mobile-nav"
                  >
                    <Sidebar
                      className={`sidebar--mobile ${
                        window?.isLandscape ? "overflow-y-scroll" : ""
                      }`}
                    />
                  </div>
                )}

                {/* Main content */}
                <Content className="main-content">
                  {/* Outlet to display the router pages */}
                  <Outlet />
                </Content>
              </div>
              <CmsFooter />
            </>
          </RequireAuth>
        </PersistLogin>
      ),
      children: [
        {
          path: "",
          element: (
            <div className="content-wrapper no-scrollbar">
              <Dashboard />
              <AlarmModal />
              <Outlet />
            </div>
          ),
          children: [
            {
              path: "alarm/:alarmId",
              element: <AlarmDetails />,
            },
            {
              path: "event/:eventId",
              element: <EventDetails />,
            },
          ],
        },
        {
          path: "property-overview",
          children: [
            {
              path: "",
              element: (
                <div className="content-wrapper overflow-y-auto">
                  <PropertyList />
                </div>
              ),
            },
            {
              path: "property/:propertyId",
              element: (
                <div className="content-wrapper overflow-y-auto">
                  <FloorList />
                  <Outlet />
                </div>
              ),
              children: [
                {
                  // mobile view for floor systems
                  path: "floor/:floorId",
                  element: <FloorSystemsTableMobile />,
                },
                {
                  path: "floor/device-details/:deviceId",
                  element: <FloorSystemDeviceDetails />,
                },
                // mobile view for system device details
                {
                  path: "floor/:floorId/device-details/:deviceId",
                  element: <FloorSystemDeviceDetails />,
                },
              ],
            },
          ],
        },
        {
          path: "statistics",
          children: [
            {
              path: "",
              element: (
                <div className="content-wrapper text-triple-white">
                  <Statistics />
                </div>
              ),
              children: [
                {
                  path: "filters",
                  element: (
                    <div className="flex items-center justify-center h-full">
                      <h1 className="page-title">Filters</h1>
                    </div>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: "events",
          children: [
            {
              path: "",
              element: (
                <div className="content-wrapper no-scrollbar">
                  <Events />
                  <Outlet />
                </div>
              ),
              children: [
                {
                  path: ":eventId",
                  element: <EventDetails />,
                },
                {
                  path: "filters",
                  element: <EventsFilter />,
                },
              ],
            },
          ],
        },
        {
          path: "flow-configuration",
          children: [
            {
              index: true,
              element: (
                <div className="content-wrapper overflow-auto">
                  <FDSSchedulesList />
                </div>
              ),
            },
            {
              path: "create-flow-configuration",
              element: (
                <div className="content-wrapper overflow-y-auto">
                  <FdsFiltersValuesProvider>
                    <CreateFlowConfiguration />
                  </FdsFiltersValuesProvider>
                </div>
              ),
            },
          ],
        },
        {
          path: "schedule",
          children: [
            {
              index: true,
              element: (
                <div className="content-wrapper overflow-auto">
                  <Schedule />
                </div>
              ),
            },
            {
              path: "create-schedule",
              element: (
                <div className="content-wrapper overflow-y-auto">
                  <ScheduleSystemsProvider>
                    <CreateNewSchedule />
                  </ScheduleSystemsProvider>
                </div>
              ),
            },
            {
              path: "manage-schedule-calendar",
              element: (
                <div className="content-wrapper overflow-y-auto">
                  <ScheduleSystemsProvider>
                    <>
                      <ScheduleCalendar />
                      <Outlet />
                    </>
                  </ScheduleSystemsProvider>
                </div>
              ),
            },
          ],
        },
        {
          path: "users",
          children: [
            {
              index: true,
              element: (
                <RequireAdmin>
                  <UsersList />,
                </RequireAdmin>
              ),
            },
            {
              path: "/users/create-user",
              element: (
                <RequireAdmin>
                  <CreateUser />
                </RequireAdmin>
              ),
            },
          ],
        },
        {
          path: "property-management",
          children: [
            {
              index: true,
              element: (
                <RequireAdmin>
                  <PropertyManagement />,
                </RequireAdmin>
              ),
            },
            {
              path: ":propertyId",
              element: (
                <RequireAdmin>
                  <PropertyPreferences />,
                </RequireAdmin>
              ),
            },
          ],
        },
        {
          path: "profile",
          children: [
            {
              path: "",
              element: <Profile />,
            },
          ],
        },
      ],
    },
    {
      path: "/login",
      element: (
        <>
          <Header />
          <LoginPage />
        </>
      ),
    },
    {
      path: "/forbidden",
      element: (
        <>
          <Header />
          <Forbidden />
        </>
      ),
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
  ];

  const RoutesElement = useRoutes(routes);

  return (
    <ThemeConfigProvider>
      <div className="app">
        {RoutesElement}
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </div>
    </ThemeConfigProvider>
  );
}

export default App;
