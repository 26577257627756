import { Row, Select } from "antd";
import PropTypes from "prop-types";
import "./StatisticsConsumptionCards.scss";

function StatisticsConsumptionCards({ waterConsumptionData }) {
  return (
    <Row>
      <div className="chart-type">
        <Select
          showSearch
          placeholder="Select a property"
          optionFilterProp="property"
          className="chart-type-select"
          defaultValue="y"
          options={[{ value: "y", label: "Year" }]}
        />
      </div>
      <div className="data-cards">
        <div className="water-consumption">
          <div className="w-consumption">
            <span className="text-triple-header">
              {waterConsumptionData.TotalWaterConsumption}
            </span>
            <span className="text-triple-header">
              m<sup>3</sup>
            </span>
          </div>
          <div className="mt-2">
            <span>Consumption</span>
          </div>
        </div>
      </div>
    </Row>
  );
}

StatisticsConsumptionCards.defaultProps = {
  waterConsumptionData: {},
};

StatisticsConsumptionCards.propTypes = {
  waterConsumptionData: PropTypes.object,
};

export default StatisticsConsumptionCards;
