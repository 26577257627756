import { useState } from "react";

import { DollarOutlined, FrownOutlined } from "@ant-design/icons";
import { Skeleton, Divider, Pagination } from "antd";
import { useParams, useSearchParams } from "react-router-dom";

import useFloorsByPropertyCount from "../../../api/hooks/useFloorsByPropertyCount";
import useFloorsByPropertyId from "../../../api/hooks/useFloorsByPropertyId";
import WaterDropIcon from "../../../assets/icons/WaterDropIcon";
import AlertsModal from "../../Modals/AlertsModal";
import DevicesModal from "../../Modals/DevicesModal";
import SearchInput from "../../SearchInput/SearchInput";
import Floor from "../Floor/Floor";

import "./FloorList.scss";

function FloorList() {
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const { propertyId } = useParams();

  const pageSize = 14;
  const [searchParams, setSearchParams] = useSearchParams();

  // get search params from URl and format them
  // as expected from the API
  const params = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  const { page } = params;

  const { data: totalFloors } = useFloorsByPropertyCount({
    propertyId,
    search: debouncedSearchQuery,
  });

  const { data: property, status } = useFloorsByPropertyId({
    propertyId,
    pageSize,
    startIndex: Number(page) - 1 >= 0 ? (Number(page) - 1) * pageSize : 0,
    search: debouncedSearchQuery,
  });

  return (
    <div className="floor-list">
      <div className="flex flex-col-reverse sm:flex-row sm:items-center mb-4 md:mb-1 w-full">
        <div className="mt-6 sm:mt-0 flex-1 flex flex-col md:flex-row md:items-center mr-auto ">
          <h1 className="page-title mb-0">{property?.Name}</h1>

          <div className="states md:ml-4">
            <div>
              <span className="square square--blue">&nbsp;</span>
              <span>Neutral state</span>
            </div>
            <div>
              <span className="square square--orange">&nbsp;</span>
              <span>Negative state</span>
            </div>
          </div>
        </div>

        <SearchInput
          className="ml-auto w-full sm:w-fit"
          placeholder="Search Floors"
          value={searchQuery}
          disabled={
            (!searchQuery &&
              (property?.Floors?.length === 0 || !property?.Floors)) ||
            status === "loading"
          }
          onChange={(e) => {
            if (e.target.value === "") {
              setDebouncedSearchQuery("");
            }
            setSearchQuery(e.target.value);
            setSearchParams({ page: 0 });
          }}
          onSearch={() => setDebouncedSearchQuery(searchQuery)}
        />
      </div>

      <div className="text-white text-xs text-opacity-80 font-light mb-4">
        <span>{property?.Address || "-"}</span>
        <Divider type="vertical" />
        <span>{property?.SystemsCount || 0} Hubs</span>
        <Divider type="vertical" />
        <DevicesModal
          devicesCount={property?.DevicesCount}
          propertyId={property?.Id}
        />
        <Divider type="vertical" />
        <AlertsModal
          alertsCount={property?.AlertsCount}
          propertyId={property?.Id}
        />
        <Divider type="vertical" />
        <span>
          <WaterDropIcon className="mr-1 w-[0.6rem] align-sub" />
          Consumption:{" "}
          {property?.TotalWaterConsumption?.CurrentMonthValue || "-"} L
        </span>
        <Divider type="vertical" />
        <span>
          <DollarOutlined className="mr-1" />
          Savings:{" "}
          {property?.TotalWaterConsumption?.WaterSavingsPercentage || "-"}%
        </span>
      </div>

      {status === "loading" &&
        Array.from({ length: pageSize }, (value, index) => index).map((el) => (
          <Skeleton
            active
            paragraph={{
              rows: 2,
            }}
            key={el}
          />
        ))}

      {status === "success" &&
        (property?.Floors?.length === 0 || !property?.Floors) && (
          <div className="p-6 text-white text-opacity-80">
            <FrownOutlined className="text-xl mr-2" />
            We couldn`t find any matches
          </div>
        )}

      {status === "success" && property?.Floors?.length > 0 && (
        <div className="sm:grid sm:grid-cols-2 lg:grid-cols-1 gap-x-6 lg:gap-x-0">
          {property?.Floors?.map((floor) => (
            <Floor key={floor.Id} floor={floor} />
          ))}
        </div>
      )}

      {status === "success" &&
        property?.Floors?.length > 0 &&
        totalFloors > pageSize && (
          <Pagination
            defaultCurrent={1}
            pageSize={pageSize}
            total={totalFloors}
            current={Number(searchParams.get("page")) || 1}
            showSizeChanger={false}
            size={window?.width <= 576 && "small"}
            onChange={(page) => {
              setSearchParams({ page });
            }}
          />
        )}
    </div>
  );
}

export default FloorList;
