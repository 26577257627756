import dayjs from "dayjs";

const statisticsMonthlyChartDataSkeleton = {
  Year: dayjs().$y,
  Month: "MMM",
  StatisticsData: [
    {
      Day: 1,
      WaterConsumption: 0,
    },
    {
      Day: 2,
      WaterConsumption: 0,
    },
    {
      Day: 3,
      WaterConsumption: 0,
    },
    {
      Day: 4,
      WaterConsumption: 0,
    },
    {
      Day: 5,
      WaterConsumption: 0,
    },
    {
      Day: 6,
      WaterConsumption: 0,
    },
    {
      Day: 7,
      WaterConsumption: 0,
    },
    {
      Day: 8,
      WaterConsumption: 0,
    },
    {
      Day: 9,
      WaterConsumption: 0,
    },
    {
      Day: 10,
      WaterConsumption: 0,
    },
    {
      Day: 11,
      WaterConsumption: 0,
    },
    {
      Day: 12,
      WaterConsumption: 0,
    },
    {
      Day: 13,
      WaterConsumption: 0,
    },
    {
      Day: 14,
      WaterConsumption: 0,
    },
    {
      Day: 15,
      WaterConsumption: 0,
    },
    {
      Day: 16,
      WaterConsumption: 0,
    },
    {
      Day: 17,
      WaterConsumption: 0,
    },
    {
      Day: 18,
      WaterConsumption: 0,
    },
    {
      Day: 19,
      WaterConsumption: 0,
    },
    {
      Day: 20,
      WaterConsumption: 0,
    },
    {
      Day: 21,
      WaterConsumption: 0,
    },
    {
      Day: 22,
      WaterConsumption: 0,
    },
    {
      Day: 23,
      WaterConsumption: 0,
    },
    {
      Day: 24,
      WaterConsumption: 0,
    },
    {
      Day: 25,
      WaterConsumption: 0,
    },
    {
      Day: 26,
      WaterConsumption: 0,
    },
    {
      Day: 27,
      WaterConsumption: 0,
    },
    {
      Day: 28,
      WaterConsumption: 0,
    },
    {
      Day: 29,
      WaterConsumption: 0,
    },
    {
      Day: 30,
      WaterConsumption: 0,
    },
    {
      Day: 31,
      WaterConsumption: 0,
    },
  ],
  TotalWaterConsumption: 0,
};

export default statisticsMonthlyChartDataSkeleton;
