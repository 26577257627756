import { useEffect, useState } from "react";

import { Divider } from "antd";
import PropTypes from "prop-types";

function DateAndTime({ className }) {
  const [today, setToday] = useState(new Date());

  useEffect(() => {
    setInterval(() => setToday(new Date()), 1000);
  }, []);

  return (
    <div
      className={`date-and-time ${className} font-light uppercase tracking-widest text-14`}
    >
      <span>
        {today.toLocaleDateString("en-us", {
          weekday: "long",
          month: "long",
          day: "numeric",
        })}
      </span>
      <Divider type="vertical" />
      <span className="inline-block w-20">
        {today.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: false,
        })}
      </span>
    </div>
  );
}

DateAndTime.propTypes = {
  className: PropTypes.string,
};

DateAndTime.defaultProps = {
  className: "",
};

export default DateAndTime;
