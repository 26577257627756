import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useStatisticsMonthlyWaterConsumption(
  year,
  month,
  params,
  options,
  key = "getMonthlyWaterConsumption",
) {
  const axiosPrivate = useAxiosPrivate();
  const getMonthlyWaterConsumption = async () => {
    const statisticsData = await axiosPrivate({
      method: "GET",
      url: `Statistics/WaterConsumption/${year}/${month}`,
      params,
    }).then((response) => response.data);

    const mappedData = {
      Year: statisticsData.Year,
      Month: statisticsData.Month,
      TotalWaterConsumption: statisticsData.TotalWaterConsumption / 1000,
      StatisticsData: statisticsData.StatisticsData?.map((v) => ({
        Day: v.Day,
        WaterConsumption: v.WaterConsumption / 1000,
        Offline: v.Offline,
        Check: v.Check,
        Leak: v.Leak,
        HasOffline: v.HasOffline,
        HasCheck: v.HasCheck,
        HasLeak: v.HasLeak,
      })),
    };

    return mappedData;
  };

  return useQuery(
    [key],
    () => getMonthlyWaterConsumption(year, month, params),
    {
      ...options,
    },
  );
}

export default useStatisticsMonthlyWaterConsumption;
