import { EditOutlined, MoreOutlined } from "@ant-design/icons";
import { Dropdown, Button } from "antd";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import DateConstants from "../../../constants/DateConstants";
import CustomTable from "../../CustomTable/CustomTable";
import DeleteSchedule from "../DeleteSchedule/DeleteSchedule";

const propTypes = {
  data: PropTypes.array,
  status: PropTypes.string,
  pageSize: PropTypes.number,
  totalCount: PropTypes.number,
  onChange: PropTypes.func,
};

const defaultProps = {
  data: [],
  status: "loading",
  pageSize: 3,
  totalCount: 0,
  onChange: () => {},
};

function SceduleTable({ data, status, pageSize, totalCount, onChange }) {
  const navigate = useNavigate();

  const generateItems = (program) => {
    const items = [
      {
        key: "edit-schedule-button",
        label: (
          <Button
            type="link"
            size="small"
            onClick={() =>
              navigate(
                `/schedule/manage-schedule-calendar?programId=${program?.ScheduleProgramId}`,
              )
            }
          >
            <EditOutlined />
            Edit
          </Button>
        ),
      },
      {
        key: "delete-schedule-button",
        label: <DeleteSchedule program={program} />,
      },
    ];
    return items;
  };

  const userDropdownMenu = (menu) => (
    <div className="user-details-dropdown-content">{menu}</div>
  );

  const columns = [
    {
      title: "Schedule Name",
      key: "ScheduleProgramName",
      render: (record) => {
        return (
          <span className="text-white text-opacity-80 font-light">
            {record?.ScheduleProgramName || "Unnamed Schedule"}
          </span>
        );
      },
      width: 200,
    },
    {
      title: "System Name",
      key: "SystemName",
      render: (record) => {
        return (
          <span className="text-white text-opacity-80 font-light">
            {record?.SystemName || "-"}
          </span>
        );
      },
      width: 200,
    },
    {
      title: "Property",
      key: "PropertyName",
      render: (record) => {
        return (
          <span className="text-white text-opacity-80 font-light">
            {record?.PropertyName || "-"}
          </span>
        );
      },
      width: 200,
    },
    {
      title: "Floor",
      key: "FloorName",
      render: (record) => {
        return (
          <span className="text-white text-opacity-80 font-light">
            {record?.FloorName || "-"}
          </span>
        );
      },
      width: 125,
    },
    {
      title: "Device Type",
      key: "DeviceType",
      render: (record) => {
        return (
          <span className="text-white text-opacity-80 font-light">
            {record?.DeviceType || "-"}
          </span>
        );
      },
      width: 125,
    },
    {
      title: "Device Serial Number",
      key: "DeviceSerialNumber",
      render: (record) => {
        return (
          <span className="text-white text-opacity-80 font-light">
            {record?.DeviceSerialNumber || "-"}
          </span>
        );
      },
      width: 200,
    },
    {
      title: "Active Days",
      key: "activeDays",
      render: (record) => {
        return (
          <span className="text-white text-opacity-80 font-light">
            {record?.ActiveDays?.length === 7 && record?.ActiveDays?.length > 0
              ? "Every day"
              : record.Intervals.map((interval) =>
                  new Date(interval.Start * 1000).getDay(),
                )
                  .sort()
                  .map((nDayWeek) => DateConstants.daysOfWeek[nDayWeek])
                  .join(", ")}
          </span>
        );
      },
      width: 200,
    },
    {
      title: "Time",
      key: "time",
      render: (record) => {
        const startAtEpoch = record?.Intervals[0]?.Start;
        const startAt = dayjs(startAtEpoch * 1000);
        const duration = record?.Intervals[0]?.Duration || 0;
        const endAt = dayjs(startAt).add(duration, "minute");

        return (
          <span className="text-white text-opacity-80 font-light">
            {`${dayjs(startAt).format("HH:mm")} - ${endAt.format("HH:mm")}`}
          </span>
        );
      },
      width: 125,
    },
    {
      title: <span>&nbsp;</span>,
      key: "actions",
      render: (record) => {
        const items = generateItems(record);
        return (
          <Dropdown
            dropdownRender={userDropdownMenu}
            menu={{ items }}
            placement="bottom"
            trigger="click"
            overlayStyle={{ width: "150px" }}
          >
            <Button type="link" className="h-auto">
              <MoreOutlined className="text-white text-opacity-80" />
            </Button>
          </Dropdown>
        );
      },
      width: 75,
      onCell: () => {
        return {
          onClick: (e) => {
            e.stopPropagation();
          },
        };
      },
    },
  ];

  return (
    <CustomTable
      className={`${!pageSize ? "opacity-0" : "opacity:100"}`}
      data={data}
      status={status}
      autoRows
      noPagination={!(totalCount > pageSize) || status === "loading"}
      tableColumns={columns}
      totalCount={totalCount}
      onChange={onChange}
      onRowClick={(_, record) => {
        navigate(
          `/schedule/manage-schedule-calendar?programId=${record?.ScheduleProgramId}`,
        );
      }}
      // onRowClick={(e, record) => {
      //   const eventId = record?.LogsInfo.Id;
      //   // don't navigate to event details if user is selecting text from the table
      //   if (document.getSelection().type !== "Range") {
      //     navigate({
      //       pathname: basePath === "/" ? `/event/${eventId}` : `${eventId}`,
      //       search: location.search,
      //     });
      //   }
      // }}
    />
  );
}

SceduleTable.propTypes = propTypes;
SceduleTable.defaultProps = defaultProps;

export default SceduleTable;
