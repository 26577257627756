import { useEffect, useState } from "react";

import { Calendar } from "antd";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import "./StatisticsCalendar.scss";
import DateConstants from "../../../constants/DateConstants";

function StatisticsCalendar({
  handleOnChangeCalendarDateCallback,
  calendarDateValue,
}) {
  const [calendarDate, setCalendarDate] = useState({
    MM: DateConstants.months[dayjs().$M],
    m: dayjs().$M,
    year: dayjs().$y,
    d: dayjs().$D,
  });

  const onPanelChange = (value) => {
    setCalendarDate({
      MM: DateConstants.months[value.$M],
      m: value.$M,
      year: value.$y,
      d: dayjs().$D,
    });
  };

  const cellRender = () => {};

  useEffect(() => {
    if (
      calendarDateValue?.month !== undefined &&
      calendarDateValue?.year !== undefined
    ) {
      setCalendarDate({
        MM: calendarDateValue.month - 1,
        m: calendarDateValue.month - 1,
        year: calendarDateValue.year,
        d: 1,
      });
    }
  }, [calendarDateValue]);

  useEffect(() => {
    handleOnChangeCalendarDateCallback(calendarDate);
  });

  return (
    <div className="statistics-calendar mt-1 w-full">
      <Calendar
        fullscreen={false}
        onPanelChange={onPanelChange}
        mode="month"
        dateCellRender={cellRender}
        value={dayjs(
          new Date(calendarDate?.year, calendarDate?.m, calendarDate.d),
        )}
      />
    </div>
  );
}

StatisticsCalendar.defaultProps = {
  handleOnChangeCalendarDateCallback: () => {},
  calendarDateValue: {},
};

StatisticsCalendar.propTypes = {
  handleOnChangeCalendarDateCallback: PropTypes.func,
  calendarDateValue: PropTypes.object,
};

export default StatisticsCalendar;
