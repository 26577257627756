import { useEffect, useState } from "react";

import { FrownOutlined } from "@ant-design/icons";
import { Button, notification } from "antd";
import PropTypes from "prop-types";

import useStatisticsMonthlyWaterConsumption from "../../../api/hooks/useStatisticsMonthlyWaterConsumption";
import AnnualChart from "./StatisticsAnnualChart";
import statisticsAnnualChartDataSkeleton from "./StatisticsAnnualChartDataSkeleton";
import ChartTitle from "./StatisticsChartTitle";
import StatisticsMonthlyChart from "./StatisticsMonthlyChart";
import statisticsMonthlyChartDataSkeleton from "./StatisticsMonthlyChartDataSkeleton";

function StatisticsCharts({
  waterConsumptionData,
  setCalendarDateValue,
  statisticsfilters,
}) {
  const [renderMonthlyChart, setRenderMonthlyChart] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState();
  const [monthlyData, setMonthlyData] = useState();
  const year = waterConsumptionData?.Year;

  const {
    data: monthlyWaterConsumptionData,
    status: queryStatus,
    refetch: getMonthlyWaterConsumption,
  } = useStatisticsMonthlyWaterConsumption(
    year,
    selectedMonth?.MonthAsInt,
    {
      propertyId: statisticsfilters.propertyId,
      floorId: statisticsfilters.floorId,
      systemId: statisticsfilters.systemId,
    },
    { enabled: false, cacheTime: 0 },
  );

  const handleHideMonthlyChart = () => {
    setRenderMonthlyChart(false);
    setSelectedMonth(null);
  };

  // Hide the monthly chart when a new set of waterConsumptionData is retrieved.
  useEffect(() => {
    setRenderMonthlyChart(false);
    setSelectedMonth(null);
  }, [waterConsumptionData]);

  useEffect(() => {
    setRenderMonthlyChart(true);
    if (selectedMonth) {
      try {
        const data = getMonthlyWaterConsumption(
          year,
          selectedMonth?.MonthAsInt,
          {
            propertyId: statisticsfilters.propertyId,
            floorId: statisticsfilters.floorId,
            systemId: statisticsfilters.systemId,
          },
        ).then((response) => {
          if (response.status === "error") {
            setMonthlyData(null);
            notification.error({
              message: (
                <span className="uppercase tracking-widest">
                  Something went wrong
                </span>
              ),
              // description:, see error to determine description
              placement: "topRight",
              icon: <FrownOutlined className="text-triple-red" />,
            });
          }

          if (response.status === "success") {
            setMonthlyData(response.data);
            setCalendarDateValue({
              month: selectedMonth?.MonthAsInt,
              year: data?.Year,
            });
          }
        });
      } catch (ex) {
        setMonthlyData(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth, getMonthlyWaterConsumption]);

  return (
    <div className="w-full statistics-charts" style={{ height: 288 }}>
      <ChartTitle
        waterConsumptionData={
          waterConsumptionData?.StatisticsData.length === 0
            ? statisticsAnnualChartDataSkeleton
            : waterConsumptionData
        }
      />
      <AnnualChart
        waterConsumptionData={
          waterConsumptionData?.StatisticsData.length === 0
            ? statisticsAnnualChartDataSkeleton
            : waterConsumptionData
        }
        setSelectedMonth={setSelectedMonth}
      />
      {queryStatus === "success" &&
      renderMonthlyChart === true &&
      selectedMonth &&
      monthlyData ? (
        <div
          className="w-full statistics-charts-monthly"
          style={{ height: 288 }}
        >
          <Button
            type="primary"
            size="small"
            className="mt-1"
            onClick={handleHideMonthlyChart}
          >
            close monthly chart
          </Button>
          <ChartTitle
            waterConsumptionData={monthlyWaterConsumptionData}
            selectedMonth={selectedMonth}
          />
          <StatisticsMonthlyChart
            waterConsumptionData={
              monthlyWaterConsumptionData?.StatisticsData?.length === 0
                ? statisticsMonthlyChartDataSkeleton
                : monthlyData
            }
            year={monthlyData?.Year}
            handleHideMonthlyChart={handleHideMonthlyChart}
          />
        </div>
      ) : null}
    </div>
  );
}

StatisticsCharts.defaultProps = {
  waterConsumptionData: {},
  statisticsfilters: {},
  setCalendarDateValue: PropTypes.func,
};

StatisticsCharts.propTypes = {
  waterConsumptionData: PropTypes.object,
  statisticsfilters: PropTypes.object,
  setCalendarDateValue: PropTypes.func,
};

export default StatisticsCharts;
