import { useEffect, useState, useRef } from "react";

import {
  UserOutlined,
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
  LoginOutlined,
} from "@ant-design/icons";
import { Card, Form, Input, Button, message } from "antd";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";

import { authenticateUser } from "../../api/requests/auth";
import useAuth from "../../hooks/useAuth";

import "./LoginPage.scss";

function LoginPage() {
  const usernameInputRef = useRef();
  const passwordInputRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || "/";

  useEffect(() => {
    usernameInputRef.current.focus();
  }, []);

  useEffect(() => {
    if (errorMessage) {
      message.error(errorMessage, 5).then(() => setErrorMessage(""));
    }
  }, [errorMessage]);

  const handleSubmit = async () => {
    setIsLoading(true);
    const username = usernameInputRef?.current?.input.value;
    const password = passwordInputRef?.current?.input.value;

    try {
      const response = await authenticateUser(username, password);
      const accessToken = response?.access_token;
      const refreshToken = response?.refresh_token;
      // BIG no-no
      Cookies.set("refreshToken", refreshToken, { expires: 1 / 48 }); // expires in 30min
      sessionStorage.setItem("previouslyVisited", "true");

      // populate the auth context (found in AuthProvider)
      setAuth({ username, accessToken, isAuthenticated: true });
      navigate(from, { replace: true });
    } catch (err) {
      if (!err?.response) {
        setErrorMessage("No server response.");
      } else if (!err.response?.data?.Message) {
        setErrorMessage("Something went wrong.");
      } else {
        setErrorMessage(err.response.data.Message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = (visible) => {
    return visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />;
  };

  return (
    <div className="login-page ">
      <Card className="login-card">
        <div className="login-content">
          <h1 className="login-title">Login</h1>
          <div className="login-copy">
            <span>Welcome to Wasens Monitoring Station</span>
          </div>
          <Form
            name="login-form"
            layout="vertical"
            requiredMark={false}
            onFinish={handleSubmit}
          >
            <Form.Item
              className=" mb-4"
              label="Username"
              name="username"
              rules={[
                { required: true, message: "Please input your Username" },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                size="large"
                ref={usernameInputRef}
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your Password" },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                iconRender={(visible) => togglePasswordVisibility(visible)}
                size="large"
                ref={passwordInputRef}
              />
            </Form.Item>
            <Form.Item className="mb-0">
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="login-button"
                loading={isLoading}
              >
                {!isLoading && <LoginOutlined />}
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
}

export default LoginPage;
