import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import PropTypes from "prop-types";

import "./PropertyWaterConsumptionChart.scss";
import WaterDropIcon from "../../../assets/icons/WaterDropIcon";
import useWindowSize from "../../../hooks/useWindowSize";

const propTypes = { className: PropTypes.string, data: PropTypes.any };
const defaultProps = { className: "", data: [] };

function PropertyWaterConsumptionChart({ className, data }) {
  const months = [
    "Jan.",
    "Feb.",
    "Mar.",
    "Apr.",
    "May",
    "Jun.",
    "Jul.",
    "Aug.",
    "Sept.",
    "Oct.",
    "Nov.",
    "Dec.",
  ];

  const window = useWindowSize();

  const isCurrentMonthValueBigger =
    data?.CurrentMonthValue > 0 &&
    data.CurrentMonthValue > data.PreviousMonthValue;
  const isPreviousMonthValueBigger =
    data?.PreviousMonthValue > 0 &&
    data.PreviousMonthValue > data.CurrentMonthValue;

  const tooltipText =
    "This is a comparison between this month and the last one.";

  return (
    <div className={`property-water-consumption ${className}`}>
      <h3 className="property-charts-title">
        <WaterDropIcon className="hidden md:inline-block align-top" />
        <span className="inline-block mx-1 mb-2 md:mb-4">
          Water Consumption
        </span>
        <Tooltip
          placement="top"
          title={tooltipText}
          color="#303343"
          className="hidden lg:inline-block"
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </h3>
      <div className="property-water-consumption-chart">
        <div className="property-water-consumption-chart-row">
          <span className="property-water-consumption-chart-month">
            {(data && months[data.CurrentMonthName - 1]) || "-"}
          </span>
          <div className="flex">
            <div className="w-[60px] h-4 mr-2 flex border border-solid border-[#676383] rounded-sm">
              {data && data?.CurrentMonthValue > 0 && (
                <span
                  style={{
                    width:
                      (isCurrentMonthValueBigger
                        ? "100%" // 100% of the width
                        : `${
                            (data.CurrentMonthValue / data.PreviousMonthValue) *
                            100 // the difference between the two months in %
                          }%`) || "0px",
                  }}
                  className="bg-triple-blue  inline-block"
                >
                  &nbsp;
                </span>
              )}
            </div>

            {(window?.width > 768 || window?.width < 576) && (
              <span className="text-secondary-white text-10">
                {data?.CurrentMonthValue || "0"} L
              </span>
            )}
          </div>
        </div>
        <div className="property-water-consumption-chart-row">
          <span className="property-water-consumption-chart-month">
            {(data && months[data.PreviousMonthName - 1]) || "-"}
          </span>
          <div className="flex">
            <div className="w-[60px] h-4 mr-2 flex border border-solid border-[#676383] rounded-sm">
              {data && data?.PreviousMonthValue > 0 && (
                <span
                  style={{
                    width:
                      (isPreviousMonthValueBigger
                        ? "100%" // 100% of the width
                        : `${
                            (data.PreviousMonthValue / data.CurrentMonthValue) *
                            100 // the difference between the two months in %
                          }%`) || "0px",
                  }}
                  className="bg-triple-orange inline-block"
                >
                  &nbsp;
                </span>
              )}
            </div>

            {(window?.width > 768 || window?.width < 576) && (
              <span className="text-secondary-white text-10">
                {data?.PreviousMonthValue || "0"} L
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

PropertyWaterConsumptionChart.propTypes = propTypes;
PropertyWaterConsumptionChart.defaultProps = defaultProps;

export default PropertyWaterConsumptionChart;
