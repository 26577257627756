import EU from "./EU";
import IL from "./IL";
import RU from "./RU";
import USA from "./USA";

const CountryFlags = {
  EU,
  IL,
  RU,
  USA,
};

export default CountryFlags;
