import { EditOutlined } from "@ant-design/icons";
import { Row, Col, Divider, Button } from "antd";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import logo from "../../../assets/img/property-overview-PIC-01.png";
import useWindowSize from "../../../hooks/useWindowSize";

function PropertyManagementCard({ className, property }) {
  const navigate = useNavigate();
  const window = useWindowSize();

  return (
    <div role="none" className={`property ${className} border-triple-blue`}>
      <Row className="h-full">
        <Col xs={{ span: 24 }} lg={{ span: 6 }}>
          <div
            style={{
              backgroundImage: `url(${
                property.ImageUrl ? property.ImageUrl : logo
              })`,
              backgroundSize: property?.ImageUrl ? "cover" : "contain",
            }}
            className={`property-image h-44 ${
              window?.width < 991 ? "property-image--blured-bottom" : ""
            }`}
          >
            &nbsp;
          </div>
        </Col>

        <Col xs={{ span: 24 }} lg={{ span: 6 }}>
          <div className={`property-details ${className}`}>
            <h3 className="property-details-title">{property?.Name}</h3>
            <div className="property-details-address text-15 text-secondary-white">
              <span className="xxl:inline-block">{property?.Address}</span>
            </div>
          </div>
        </Col>

        <Col
          xs={{ span: 22, offset: 1 }}
          lg={{ span: 1 }}
          className="flex items-center justify-center lg:m-0"
        >
          <Divider
            type={window?.width > 992 ? "vertical" : "horizontal"}
            className="lg:h-4/5 m-0"
          />
        </Col>

        <Col
          xs={{ span: 24 }}
          lg={{ span: 11 }}
          className="flex justify-end items-center"
        >
          <Button
            type="primary"
            onClick={() => navigate(`${property?.Id}`)}
            size="large"
            icon={<EditOutlined />}
            className="mr-2"
          >
            EDIT
          </Button>
        </Col>
      </Row>
    </div>
  );
}

PropertyManagementCard.propTypes = {
  className: PropTypes.string,
  property: PropTypes.object,
};

PropertyManagementCard.defaultProps = {
  className: "",
  property: {},
};

export default PropertyManagementCard;
