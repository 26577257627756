import { useEffect } from "react";

import { FrownOutlined, LeftOutlined, SmileOutlined } from "@ant-design/icons";
import { Button, Divider, Space, notification } from "antd";
import dayjs from "dayjs";
import { useNavigate, useSearchParams } from "react-router-dom";

import useCreateSchedule from "../../../api/hooks/useCreateSchedule";
import useScheduleAmend from "../../../api/hooks/useScheduleAmend";
import useScheduleById from "../../../api/hooks/useScheduleById";
import { defaultScheduleSystems } from "../../../context/ScheduleSystemsProvider";
import useScheduleSystems from "../../../hooks/useScheduleSystems";
import CreateNewScene from "../CreateNewScene/CreateNewScene";
import ScheduleCalendarTable from "./ScheduleCalendarTable";

const duration = require("dayjs/plugin/duration");
const weekday = require("dayjs/plugin/weekday");

dayjs.extend(weekday);
dayjs.extend(duration);

function ScheduleCalendar() {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const programId = search.get("programId");
  const { scheduleSystems, setScheduleSystems } = useScheduleSystems();
  const { mutate: createSchedule, isLoading: isCreating } = useCreateSchedule();
  const {
    data: schedule,
    refetch: getScheduleById,
    isFetching,
  } = useScheduleById(programId, {
    enabled: false,
    cacheTime: 0,
  });
  const { mutate: amendSchedule } = useScheduleAmend();

  useEffect(() => {
    if (programId) {
      getScheduleById();
    }
  }, [getScheduleById, programId]);

  const daysOfWeek = {
    mon: 1,
    tue: 2,
    wed: 3,
    thu: 4,
    fri: 5,
    sat: 6,
    sun: 7,
  };

  const onSuccess = (data, type) => {
    if (!data.HasFailed) {
      notification.success({
        message: (
          <span className="uppercase tracking-widest">
            Schedule {type === "create" ? "created" : "updated"} successfully
          </span>
        ),
        // description:, see error to determine description
        placement: "topRight",
        icon: <SmileOutlined className="text-triple-green" />,
      });
    }
  };

  const onError = () => {
    notification.error({
      message: (
        <span className="uppercase tracking-widest">Something went wrong</span>
      ),
      // description:, see error to determine description
      placement: "topRight",
      icon: <FrownOutlined className="text-triple-red" />,
    });
  };

  const onSettled = () => {
    setScheduleSystems(defaultScheduleSystems);
    navigate("/schedule", { replace: true });
  };

  const handleScheduleCreate = () => {
    const requestsToSend = scheduleSystems.scheduleIntervals?.map(
      (interval) => {
        const startHour = Number(interval.startTime.substring(0, 2));
        const endHour = Number(interval.endTime.substring(0, 2));
        const startMinutes = Number(interval.startTime.substring(3, 5));
        const endMinutes = Number(interval.endTime.substring(3, 5));
        let durationInMins;
        return {
          DeviceUniqueIds: scheduleSystems?.selectedDevicesUniqueIds,
          Program: {
            DeviceUid: "", // Not used, can be left empty
            Enabled: true,
            Name: scheduleSystems?.scheduleName, // name of the schedule
            Action: null, // leave null for default schedule
            Type: "Weekly", // Cyclic, Periodic, Weekly. Use Weekly
            Intervals: interval.days.map((day) => {
              const startDateTime = dayjs()
                .weekday(daysOfWeek[day])
                .set("hour", startHour)
                .set("minute", startMinutes)
                .set("second", 0);

              const endDateTime = dayjs()
                .weekday(daysOfWeek[day])
                .set("hour", endHour)
                .set("minute", endMinutes)
                .set("second", 0);

              if (endDateTime < startDateTime) {
                const pEndDate = endDateTime.add(1, "day");
                durationInMins = dayjs
                  .duration(pEndDate.diff(startDateTime))
                  .as("minutes");
              } else {
                durationInMins = dayjs
                  .duration(endDateTime.diff(startDateTime))
                  .as("minutes");
              }
              const epoch = startDateTime.unix();
              return {
                Start: epoch, // Epoch time of the first time to start the schedule (ex: 1598533560 )
                Duration: durationInMins, // duration in minutes
                Cycle: 168, // Use 168 for weekly
                Action: null, // leave null for default schedule
              };
            }),
          },
        };
      },
    );

    requestsToSend?.forEach((program) => {
      createSchedule(program, {
        onSuccess: (data) => onSuccess(data, "create"),
        onError,
        onSettled,
      });
    });
  };

  const handleScheduleAmend = () => {
    let durationInMins;
    const newIntervals = scheduleSystems.scheduleIntervals
      ?.map((interval) => {
        const startHour = Number(interval.startTime.substring(0, 2));
        const endHour = Number(interval.endTime.substring(0, 2));
        const startMinutes = Number(interval.startTime.substring(3, 5));
        const endMinutes = Number(interval.endTime.substring(3, 5));

        return interval.days.map((day) => {
          const startDateTime = dayjs()
            .weekday(daysOfWeek[day])
            .set("hour", startHour)
            .set("minute", startMinutes)
            .set("second", 0);

          const endDateTime = dayjs()
            .weekday(daysOfWeek[day])
            .set("hour", endHour)
            .set("minute", endMinutes)
            .set("second", 0);

          if (endDateTime < startDateTime) {
            const pEndDate = endDateTime.add(1, "day");
            durationInMins = dayjs
              .duration(pEndDate.diff(startDateTime))
              .as("minutes");
          } else {
            durationInMins = dayjs
              .duration(endDateTime.diff(startDateTime))
              .as("minutes");
          }

          const epoch = startDateTime.unix();
          return {
            Start: epoch, // Epoch time of the first time to start the schedule (ex: 1598533560 )
            Duration: durationInMins, // duration in minutes
            Cycle: 168, // Use 168 for weekly
            Action: null, // leave null for default schedule
          };
        });
      })
      .flat();

    const updatedProgram = {
      ProgramId: schedule?.ProgramId,
      Enabled: schedule?.Enabled,
      Name: schedule?.Name,
      Action: schedule?.Action,
      Intervals: newIntervals,
    };

    amendSchedule(updatedProgram, {
      onSuccess: (data) => onSuccess(data, "update"),
      onError,
      onSettled,
    });
  };

  // useEffect(() => {
  //   if (!programId && scheduleSystems?.selectedDevicesUniqueIds?.length === 0) {
  //     navigate("/schedule/create-schedule", { replace: true });
  //   }
  // }, [programId, scheduleSystems?.selectedDevicesUniqueIds?.length, navigate]);

  // useEffect(() => {
  //   console.log("scheduleSystems", scheduleSystems);
  // }, [scheduleSystems]);

  return (
    <div className="schedule-calendar">
      <div>
        <Button
          className="mb-5 self-start uppercase tracking-widest"
          type="link"
          onClick={() => navigate("/schedule")}
          //   {
          //   if (!schedule) {
          //     navigate(-1);
          //   } else {
          //     navigate("/schedule");
          //   }
          // }
        >
          <LeftOutlined />
          Back
        </Button>
        <div className="schedule-header mb-8">
          <div className="flex flex-col">
            <h1 className="page-title mb-2">
              {scheduleSystems.scheduleName ||
                schedule?.Name ||
                "Unnamed Schedule"}
            </h1>
            {!schedule && (
              <div className="text-white text-opacity-80 text-15 font-light">
                <span>
                  Property: {scheduleSystems.selectedProperty?.Name || "-"}
                </span>
                <Divider type="vertical" />
                <span>
                  Device Type: {scheduleSystems.selectedDeviceType?.Name || "-"}
                </span>
                <Divider type="vertical" />
                <span>
                  Systems:{" "}
                  {scheduleSystems.confirmedSystemsWithSelectedDevice.length}
                </span>
                <Divider type="vertical" />
                <span>
                  Devices: {scheduleSystems.selectedDevicesUniqueIds.length}
                </span>
              </div>
            )}
          </div>

          <Space>
            <CreateNewScene isDisabled={isCreating} />
            <Button
              size="large"
              type="primary"
              className="ml-auto"
              loading={isCreating}
              disabled={!scheduleSystems?.scheduleIntervals?.length > 0}
              onClick={() => {
                if (schedule) {
                  handleScheduleAmend();
                } else {
                  handleScheduleCreate();
                }
              }}
            >
              Save schedule
            </Button>
          </Space>
        </div>
      </div>

      <ScheduleCalendarTable schedule={schedule} isLoading={isFetching} />
    </div>
  );
}

export default ScheduleCalendar;
