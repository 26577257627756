import PropTypes from "prop-types";

function StatisticsTooltip({ active, payload, year, chartType }) {
  if (active && payload && payload.length) {
    if (chartType === "Annual") {
      return (
        <div className="border border-solid rounded bg-triple-white text-triple-background border-sky-500">
          <p className="px-2 my-1">{`${payload[0].payload.Month}/${year}`}</p>
          <p className="px-2 my-1">{`${payload[0].value} m3`}</p>
        </div>
      );
    }

    if (chartType === "Monthly") {
      return (
        <div className="border border-solid rounded bg-triple-white text-triple-background border-sky-500">
          <p className="px-2 my-1">{`${payload[0].value} m3`}</p>
        </div>
      );
    }
  }
}
StatisticsTooltip.defaultProps = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  year: PropTypes.number,
  chartType: PropTypes.string,
};

StatisticsTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  year: PropTypes.number,
  chartType: PropTypes.string,
};
export default StatisticsTooltip;
