import { Footer } from "antd/es/layout/layout";

function CmsFooter() {
  return (
    <Footer className="text-triple-white mt-auto sm:text-right text-center bg-triple-sidebar">
      {process.env.REACT_APP_VERSION}
    </Footer>
  );
}

export default CmsFooter;
